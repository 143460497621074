import React, { Component } from "react";

class Result extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: "",
      errorCode: ""
    };
  }

  async componentDidMount() {
    let search = this.props.location.search;
    search = search.replace("?", "");
    if (search.indexOf("error") > -1) {
      const error = search.split('=')[1];
      let errorCode = 'default';
      switch (error) {
        case 'idcard_not_found' : errorCode = 'wrong_idcard'; break;
        case 'client_not_found' : errorCode = 'wrong_clientid'; break;
        default : break;
      }
      await this.setState({ status: "error", errorCode });
    } else {
      await this.setState({ status: "success" });
      const liff = window.liff;
      if (liff.isInClient()) {
        setTimeout(async () => {
          await liff.sendMessages([
            {
              type:'text',
              text: 'ตรวจสอบคะแนน'
            }
          ]);
          liff.closeWindow();
        }, 5000);
      }
    }
    this.gotoTopPage();
  }

  gotoTopPage = () => {
    //Go to top page
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; 
  }

  getDisplay = () => {
    let search = this.props.location.search;
    let message = 'ไม่สามารถทำรายการได้ในขณะนี้';
    search = search.replace("?", "");
    const error = search.split('=')[1];
    switch (error) {
      case 'idcard_not_found' : message = 'เลขบัตรประชาชนของท่านไม่ถูกต้อง'; break;
      case 'client_not_found' : message = 'ไม่พบหมายเลยสมาชิก'; break;
      default : break;
    }
    if (this.state.status === "error") {
      return (
        <div className="wrap-content" style={{paddingBottom: '60px'}}>
          <div className="error-icon">
            <img src="/images/error-icon.png" alt="erroricon" />
          </div>
          <div className="alert-text">
            <h2>ขออภัยค่ะ</h2>
            <p>
                {message}
              <br />
              กรุณาลองใหม่อีกครั้ง
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="wrap-content" style={{paddingBottom: '60px'}}>
          <div className="error-icon">
            <img src="/images/success-icon.png" alt="success" />
          </div>
          <div className="alert-text success-text">
            <h2>ลงทะเบียนสำเร็จ</h2>
            <p>
              ระบบได้รับข้อมูลการลงทะเบียน
              <br />
              ของคุณเรียบร้อยเเล้ว
            </p>
          </div>
        </div>
      );
    }
  };

  goBack = async () => {
    if (this.state.status === "error") {
      this.props.history.push("/smileclub/member?" + this.state.errorCode);
    } else {
      const liff = window.liff;
      await liff.sendMessages([
        {
          type:'text',
          text: 'ตรวจสอบคะแนน'
        }
      ]);
      liff.closeWindow();
    }
  }

  render() {
    return (
      <div className="wrap-all member-info-wrap">
        <div className="wrap-logo">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="logo-smile">
            <img src="/images/alert.png" alt="alert" />
          </div>
        </div>
        {this.getDisplay()}
        <div
          className="btn-signin-fixed"
          onClick={this.goBack}
          style={{ display:  "block" }}
        >
          {
            this.state.status === "error" ? "ลองอีกครั้ง" : "เริ่มต้นใช้งาน"
          }
          
        </div>
      </div>
    );
  }
}

export default Result;
