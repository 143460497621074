import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { HttpClient } from '../../../services/HttpClient';

const TIMEOUT = 30;

class VerifyOTP extends Component<any, any> {
  timer: any;

  constructor(props: any) {
    super(props);
    this.state = {
      telephone: "",
      otp: '',
      otp_isValid: true,
      isAndroid: false,
      submitEnable: false,
      loaded: false,
      clientId: null,
      countDown: TIMEOUT,
    };
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }

  register = async () => {
    const liff = window.liff;
    const profile = await liff.getProfile();
    const httpClient = new HttpClient();
    const telephone = window.sessionStorage.getItem('telephone');
    let consent = window.sessionStorage.getItem('consent_agree');
    let common1 = window.sessionStorage.getItem('common1');
    let common2 = window.sessionStorage.getItem('common2');
    let common3 = window.sessionStorage.getItem('common3');
    if (consent) {
      consent = JSON.parse(consent);
    }
    try {
      const response = await httpClient.post("/register/mtl", {
        clientId: this.state.clientId,
        uid: profile.userId,
        consent,
        telephone,
        common1,
        common2,
        common3,
      });
      await this.setState({ submitEnable: true, loaded: true });
      const result = response.result;

      if (result === "success") {
        this.props.history.push("/mtl-connect/result?page=verifyOTP&success");
      } else {
        this.props.history.push("/mtl-connect/result?page=verifyOTP&error=" + result);
      }
    } catch (err) {
      this.props.history.push("/mtl-connect/result?page=verifyOTP&error");
    }

  }

  submitProcess = async () => {
    if (this.state.submitEnable && this.state.otp_isValid) {
      await this.setState({ submitEnable: false, loaded: false });
      const refCode = window.sessionStorage.getItem('refCode');
      const httpClient = new HttpClient();
      try {
        const response = await httpClient.post('/register/verifyOTP/mtl', {
          OTP: this.state.otp,
          refCode,
        });
        if (response.status && response.status === "valid") {
          await this.register();
        } else {
          this.props.history.push('/mtl-connect/result?page=verifyOTP&error=verify_otp_fail');
        }
      } catch (err) {
        this.props.history.push('/mtl-connect/result?page=verifyOTP&error=verify_otp_fail');
      }
      await this.setState({ submitEnable: true, loaded: true });
    }
  }

  inputChange = async (e: any) => {
    const { name, value } = e.target;
    const state: any = {};
    state[name] = value;
    await this.setState(state);
    await this.validateInput();
  };

  validateInput = async () => {
    let { otp, otp_isValid } = this.state;
    otp = otp.replace(/_/g, '');
    if (otp.length > 0) {
      otp_isValid = otp.length === 5;
      await this.setState({ otp_isValid, submitEnable: otp_isValid });
    }
  }

  async componentDidMount() {
    const clientId = window.sessionStorage.getItem('clientId');
    const telephone = window.sessionStorage.getItem('telephone');
    if (telephone) {
      await this.setState({ telephone });
      await this.validateInput();
    }

    if (clientId) {
      await this.setState({ clientId });
    } else {
      this.props.history.push(`/mtl-connect/validator`);
    }

    this.startCountdown();
  }

  startCountdown = () => {
    this.timer = setInterval(() => {
      const countDown = this.state.countDown;
      this.setState({ countDown: countDown - 1})
      if (this.state.countDown < 1 ) {
        clearTimeout(this.timer);
      }
    }, 1000);
  }

  sentOTP = async () => {
    if (this.state.countDown < 1) {    
      this.setState({ countDown: TIMEOUT });
      this.startCountdown();
      const httpClient = new HttpClient();
      try {
        const response = await httpClient.post('/register/sentOTP/mtl', {
          sendTo: this.state.telephone,
        });
        window.sessionStorage.setItem('telephone', this.state.telephone);
        if (response.MessageResult === "sent" || response.MessageResult === "") {
          window.sessionStorage.setItem('refCode', response.refCode);
        } else {
          this.props.history.push('/mtl-connect/result?page=verifyOTP&error=request_otp_fail');
        }
      } catch (err) {
        this.props.history.push('/mtl-connect/result?page=verifyOTP&error=request_otp_fail');
      }
    }
  }

  render() {
    if (this.state.clientId) {
      const refCode = window.sessionStorage.getItem('refCode');
      return (
        <React.Fragment>
          <link rel="stylesheet" type="text/css" href="/css/style.css" />
          <link rel="stylesheet" type="text/css" href="/css/responsive.css"/>
          <link rel="stylesheet" type="text/css" href="/css/MTLConnect.css"/>
          <div
            id="wrap-loader"
            style={{ display: this.state.loaded ? "none" : "" }}
          >
            <div
              id="loader"
              style={{ display: this.state.loaded ? "none" : "block" }}
            ></div>
          </div>
          <div className="wrap-term connect-wrap">
            <div className="wrap-logo center">
              <div className="logo-mtl">
                <img src="/images/MTL-logo.png" alt="logo" onLoad={() => this.setState({ loaded: true })} />
              </div>
            </div>
            <div className="member-content" id="member-content">
              <div className="wrap-content connect-content">
                <h3 style={{textAlign: 'center'}}>ลงชื่อเข้าใช้งาน สำหรับลูกค้า MTL</h3>
                <div className="register-smile smile-member-info connect-input">
                  <p style={{color: '#555555', fontSize: '16px'}}>เบอร์โทรศัพท์เพื่อรับ OTP</p>
                  <div className="section_input member-input">
                    <InputMask
                      style={{textAlign: 'center'}}
                      mask="9999999999"
                      placeholder="0xxxxxxxxx"
                      type="tel"
                      name="telephone"
                      id="telephone"
                      readOnly={true}
                      value={this.state.telephone}
                      pattern="\d*"
                    />
                  </div>
                </div>
                <div className="register-smile smile-member-info connect-input">
                  <p style={{color: '#555555', fontSize: '16px'}}>ใส่ OTP ที่ได้รับ (เลขอ้างอิง {refCode})</p>
                  <div className="section_input member-input">
                    <InputMask
                      style={{textAlign: 'center'}}
                      mask="99999"
                      placeholder="xxxxx"
                      type="tel"
                      name="otp"
                      id="otp"
                      value={this.state.otp}
                      onChange={this.inputChange}
                      pattern="\d*"
                      className={
                        this.state.otp_isValid ? "" : "input-invalid"
                      }
                    />
                  </div>
                </div>
                <p 
                  onClick={this.sentOTP}
                  style={{ 
                    fontSize: '16px',
                    textAlign: 'center',
                    textDecoration: 'underline',
                    color: this.state.countDown > 0 ? '#CCCCCC': '#000000'
                  }}>
                  ส่งรหัส OTP อีกครั้ง {this.state.countDown > 0 ? `(${this.state.countDown})`: ''}
                </p>
              </div>
            </div>
            <div
              className={
                this.state.submitEnable
                  ? "btn-signin-fixed"
                  : "btn-signin-fixed btn-disabled"
              }
              onClick={this.submitProcess}
            >
              ถัดไป
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <div id="loader" style={{ display: "block" }}></div>;
    }
  }
}

export default VerifyOTP;