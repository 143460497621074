import React, { Component } from 'react';
import axios from 'axios';
import '../mtl-connect/MTLConnect.css';
import Consent from './pages/Consent';
import { Switch, Route } from 'react-router-dom';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

export class MTLTerms extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasToken: false,
    };
  }

  async componentDidMount() {
    console.log('hey');
    document.title = 'MTL Terms & Condition';
    liff
      .init({ liffId: process.env.REACT_APP_LIFF_TERM }) // staging
      // .init({ liffId: '1475387349-d3ZjMbBy' }) // prod
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const lineAccessToken = liff.getAccessToken();
          const response = await axios.post(
            `${API_URL}/register/getAccessToken`,
            { token: lineAccessToken },
          );
          const refresh_token = response.data.data;
          sessionStorage.setItem('refresh_token', refresh_token);
          await this.setState({ hasToken: true });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  render() {
    return (
      <Switch>
        <Route exact path="/mtl-terms/consent">
          <Consent loadedToken={this.state.hasToken} {...this.props} />
        </Route>
      </Switch>
    );
  }
}
