import React, { Component } from 'react';
import '../MTLTaxConsent.css';

export default class Rule extends Component<any, any> {
  async componentDidMount() {
    // const urlParams = new URLSearchParams(window.location.search);
    // const key = urlParams.get('key');
    const agree = window.sessionStorage.getItem('consent_agree');
    if (agree) this.setState({ agree: JSON.parse(agree) });
  }

  constructor(props: any) {
    super(props);
    this.state = {
      inputType: 'sms',
      telephone: '',
      email: '',
      telephone_isValid: true,
      email_isValid: true,
      submitEnable: false,
    };
  }

  handleSubmit = async () => {
    this.props.history.push('/taxConsent/check');
  };

  render() {
    return (
      <div className="wrap-all">
        <div className="wrap-logo">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="logo-smile">
            <img src="/images/Graphic.svg" alt="smileLogo" />
          </div>
        </div>
        <div className="wrap-content" style={{}}>
          <h2 className="header">
            แบบฟอร์มแจ้งความประสงค์ในการใช้สิทธิ
            <br />
            ขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร
          </h2>
          <div className="box-consent">
            <div onClick={this.handleSubmit} className="select-btn">
              ดำเนินการต่อ
            </div>
          </div>
          <div className="box-consent">
            <div className="rule">
              &emsp;ตั้งแต่ปีภาษี <b>2563</b> เป็นต้นไป หากท่านต้องการใช้สิทธิ
              ขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร ท่านต้องแจ้ง
              ความประสงค์และยินยอมให้บริษัทฯ เปิดเผยข้อมูลเกี่ยวกับ
              เบี้ยประกันภัยต่อกรมสรรพากร ตามหลักเกณฑ์ วิธีการที่
              กรมสรรพากรกำหนด
              <br />
              &emsp;และตั้งแต่วันที่ <b>1</b> มกราคม <b>2564</b> เป็นต้นมา
              บริษัทฯ ขอยกเลิกการจัดส่งหนังสือรับรองการชำระเบี้ยประกันภัยใน
              รูปแบบกระดาษ โดยท่านสามารถดาวน์โหลดหนังสือรับรอง
              การชำระเบี้ยประกันภัยในรูปแบบ <b>PDF</b> และสามารถตรวจสอบ
              เบี้ยประกันภัยสำหรับอ้างอิง ใช้สิทธิยกเว้นภาษีเงินได้
              ในเว็บไซต์นี้
            </div>
          </div>
        </div>
      </div>
    );
  }
}
