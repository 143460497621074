import React, { Component } from "react";
import { HttpClient } from '../../../services/HttpClient';

class NewConsent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentConsent: 1,
      loaded: true,
    };
  }

  async componentDidMount() {

  }

  updateConsent = async () => {
    const { agree } = this.state;
    const httpClient = new HttpClient();
    const liff = window.liff;
    const profile = await liff.getProfile();
    try {
      const response = await httpClient.post('/register/updateConsentMTL', {
        uid: profile.userId,
        consent: agree,
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  handleSubmit = async (consentCode: string | null) => {
    if (consentCode) {
      window.sessionStorage.setItem(consentCode, 'true');
    }
    this.props.history.push("/mtl-connect/validator");
  };

  gotoTopPage = () => {
    //Go to top page
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  incrementConsent = (consentCode: string | null) => {
    if (consentCode) {
      window.sessionStorage.setItem(consentCode, 'true');
    }
    const { currentConsent } = this.state;
    this.setState({ currentConsent: currentConsent + 1, loaded: false });
  }

  consentInformation = () => {
    return (
      <div className="wrap-all">
        <div className="wrap-content">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="consent-img">
            <img src="/images/PDPA Ilus-04.png" alt="PDPA" onLoad={() => this.setState({ loaded: true })} />
          </div>
          <h3>การจัดการข้อมูลส่วนบุคคล</h3>
          <p>
            ข้อมูลของคุณเป็นสิ่งสำคัญยิ่ง บริษัทจะดำเนินการอย่างดีที่สุดด้วยมาตรการที่เข้มงวดในการรักษาความลับและดูแลข้อมูลส่วนบุคคลของคุณให้ปลอดภัย คุณสามารถเลือกให้หรือไม่ให้ความยินยอมโดยไม่มีผลต่อการพิจารณาการใช้ผลิตภัณฑ์หรือบริการ
            คุณสามารถดูรายละเอียดการจัดการข้อมูลส่วนบุคคลของบริษัทในนโยบายการคุ้มครองข้อมูลส่วนบุคคลที่<a href="https://www.muangthai.co.th/th/privacy-policy" >เว็บไซต์</a>  ทั้งนี้ คุณสามารถเปลี่ยนแปลงการให้ความยินยอมได้ โดยติดต่อเราที่ email: dpo-office@muangthai.co.th หรือโทร.1766 หรือ สำนักงานสาขาทั่วประเทศ
			    </p>
          <div className="btn-agree next-btn" onClick={() => this.incrementConsent(null)}>ต่อไป</div>
        </div>
      </div>
    );
  }

  consent1 = () => {
    return (
      <div className="wrap-all">
        <div className="wrap-content">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="consent-img">
            <img src="/images/PDPA Ilus-01.png" alt="PDPA 01" onLoad={() => this.setState({ loaded: true })} />
          </div>
          <h3>ความยินยอมให้บริษัท <br />พัฒนาผลิตภัณฑ์หรือบริการให้ดียิ่งขึ้น</h3>
          <p>
            เพื่อให้คุณได้รับบริการที่ถูกใจมากยิ่งขึ้น จากการวิเคราะห์ วิจัย ทำสถิติและพัฒนา ปรับปรุงผลิตภัณฑ์หรือบริการจากบริษัท รวมถึงพันธมิตรทางธุรกิจ คุณยินยอมให้บริษัทเก็บรวบรวม ใช้ และเปิดเผยข้อมูลของคุณ
          </p>
          <div className="agree-wrap">
            <div className="btn-agree btn-not-agree" onClick={() => this.incrementConsent(null)}>ไม่ยินยอม</div>
            <div className="btn-agree" onClick={() => this.incrementConsent('common1')}>ยินยอม</div>
          </div>
        </div>
      </div>
    );
  }

  consent2 = () => {
    return (
      <div className="wrap-all">
        <div className="wrap-content">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="consent-img">
            <img src="/images/PDPA Ilus-02.png" alt="PDPA 02" onLoad={() => this.setState({ loaded: true })} />
          </div>
          <h3>ความยินยอมให้บริษัท<br />นำเสนอผลิตภัณฑ์และบริการ</h3>
          <p>
            เพื่อให้คุณไม่พลาดโอกาสรับข้อเสนอผลิตภัณฑ์หรือบริการสิทธิพิเศษในการเข้าร่วมกิจกรรมที่บริษัทจัดขึ้น รวมถึงข่าวสาร คำแนะนำที่เป็นประโยชน์และโปรโมชั่นที่คัดสรรอย่างเหมาะสม คุณยินยอมให้บริษัทเก็บรวบรวม ใช้ และเปิดเผยข้อมูลของคุณ
          </p>
          <div className="agree-wrap">
            <div className="btn-agree btn-not-agree" onClick={() => this.incrementConsent(null)}>ไม่ยินยอม</div>
            <div className="btn-agree" onClick={() => this.incrementConsent('common2')}>ยินยอม</div>
          </div>
        </div>
      </div>
    );
  }

  consent3 = () => {
    return (
      <div className="wrap-all">
        <div className="wrap-content">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="consent-img">
            <img src="/images/PDPA Ilus-03.png" alt="PDPA 03" onLoad={() => this.setState({ loaded: true })} />
          </div>
          <h3>ความยินยอมให้บริษัทในเครือ เมืองไทยประกันชีวิตและพันธมิตร ทางธุรกิจนำเสนอผลิตภัณฑ์หรือบริการ</h3>
          <p>
            เพื่อให้คุณไม่พลาดโอกาสรับข้อเสนอผลิตภัณฑ์ บริการพิเศษ หรือโปรโมชั่น ที่คัดสรรอย่างเหมาะสมจากบริษัทในเครือเมืองไทยประกันชีวิตและพันธมิตรทางธุรกิจ คุณยินยอมให้เราเปิดเผยข้อมูลของคุณให้แก่บริษัทดังกล่าว เพื่อการวิเคราะห์ และตลอดจนนำเสนอผลิตภัณฑ์และบริการ <br/> คุณสามารถดูรายละเอียดบริษัทในเครือเมืองไทยประกันชีวิตและรายชื่อพันธมิตรทางธุรกิจที่ได้รับข้อมูลของคุณได้จาก muangthai.co.th ในกรณีเพิ่มเติมผู้รับข้อมูลในภายหลัง: บริษัทจะแจ้งรายชื่อผู้รับข้อมูลใหม่ไว้บนเว็บไซต์ของบริษัท และจะขอความยินยอมจากคุณก่อนการเปิดเผยข้อมูลหากผู้รับข้อมูลไม่ได้เป็นบริษัทที่อยู่ในเครือเมืองไทยประกันชีวิต
        </p>
          <div className="agree-wrap">
            <div className="btn-agree btn-not-agree" onClick={() => this.handleSubmit(null)}>ไม่ยินยอม</div>
            <div className="btn-agree" onClick={() => this.handleSubmit('common3')}>ยินยอม</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <link rel="stylesheet" type="text/css" href="/consent/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/consent/css/responsive.css" />
        <div
            id="wrap-loader"
            style={{ display: this.state.loaded ? "none" : "" }}
          >
            <div
              id="loader"
              style={{ display: this.state.loaded ? "none" : "block" }}
            ></div>
          </div>
        {(this.state.currentConsent === 1) ? this.consentInformation() : null}
        {(this.state.currentConsent === 2) ? this.consent1() : null}
        {(this.state.currentConsent === 3) ? this.consent2() : null}
        {(this.state.currentConsent === 4) ? this.consent3() : null}
      </React.Fragment>
    );
  }
}

export default NewConsent;
