import React, { Component } from 'react';
import './MTLCertificate.css';
import TaxCertificate from './pages/TaxCertificate';
import OwnerCertificate from './pages/OwnerCertificate';
import TitleCertificate from './pages/TitleCertificate';
import { HttpClient } from '../../services/HttpClient';
import axios from 'axios';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

export default class MTLCertificate extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      customer: {},
      taxCertList: [],
      year: new Date().getFullYear() + 542,
      loaded: false,
    };
  }
  async componentDidMount() {
    try {
      document.title = 'MTL Tax Certificate';
      const httpClient = new HttpClient();
      await liff
        .init({ liffId: process.env.REACT_APP_LIFF_CERTTAX })
        .then(async () => {
          if (!liff.isLoggedIn()) {
            liff.login();
          } else {
            const lineAccessToken = await liff.getAccessToken();
            const profile = await liff.getProfile();
            const response = await axios.post(
              `${API_URL}/register/getAccessToken`,
              { token: lineAccessToken },
            );
            const refresh_token = response.data.data;
            sessionStorage.setItem('refresh_token', refresh_token);
            await httpClient.post(`/customers/tracking`, {
              uid: profile.userId,
              trackingKey: 'tax-cert-view',
            });
            const taxCert = await httpClient.get(
              `/customers/tax-cert/${this.state.year}`,
            );
            await this.setState({
              ...this.state,
              hasToken: true,
              customer: taxCert.customers,
              taxCertList: taxCert.taxCertList,
              policyList: taxCert.policyList,
            });
            this.setState({ loaded: true });
          }
        });
    } catch (error) {
      console.log(error);
      window.location.href = `https://liff.line.me/${process.env.REACT_APP_LIFF_MTL_CONNECT}`;
    }
  }

  render() {
    return (
      <div>
        <img src="./images/MTL-logo.png" alt="" className="logo" />
        <TitleCertificate year={this.state.year} />
        <OwnerCertificate
          customer={this.state.customer}
          policyCount={this.state.policyList ? this.state.policyList.length : 0}
        />
        <div className="line"></div>
        <div
          id="wrap-loader"
          style={{ display: this.state.loaded ? 'none' : '' }}
        >
          <div
            id="loader"
            style={{ display: this.state.loaded ? 'none' : 'block' }}
          ></div>
        </div>
        {this.state.policyList ? (
          <TaxCertificate
            policyList={this.state.policyList}
            lineUser={this.state.lineUser}
          />
        ) : null}
      </div>
    );
  }
}
