import React, { Component } from 'react';

export default class TitleCertificate extends Component<any, any> {
  render() {
    return (
      <div>
        <h1 className="title">หนังสือรับรองการชำระเบี้ยประกันภัย</h1>
        <h1 className="year">
          ปี <span>{this.props.year}</span>
        </h1>
        <h2 className="title-detail">
          ระบบจะแสดงข้อมูลหนังสือรับรองการชำระเบี้ยประกันภัย
          <br />
          เฉพาะกรมธรรม์ที่ยังมีผลบังคับและสามารถใช้สิทธิยื่นหักลดหย่อนภาษีได้เท่านั้น
          <br />
          สำหรับกรมธรรม์ที่สิ้นผลบังคับไปแล้ว
          <br />
          กรุณาติดต่อที่โทร. 1766 เพื่อขอหนังสือรับรองการชำระเบี้ยประกันภัย
        </h2>
      </div>
    );
  }
}
