import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { HttpClient } from '../../../services/HttpClient';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';


class RequestOTP extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      telephone: "",
      isAndroid: false,
      telephone_isValid: true,
      submitEnable: false,
      loaded: false,
      clientId: null,
      openDialog: false,
      openDialogMobile: false,
    };
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }

  submitProcess = async () => {
    if (this.state.submitEnable && this.state.telephone_isValid) {
      await this.setState({ submitEnable: false, loaded: false });
      const httpClient = new HttpClient();
      try {
        const isBlacklist = await this.checkBlacklist(httpClient);
        const validMobile = await this.verifyNumber(httpClient);
        if (isBlacklist) {
          return;
        }
        if(!validMobile) {
          this.setState({ openDialogMobile: true, submitEnable: true, loaded: true });
          return;
        } else {
          await this.requestOtp(httpClient);
        }
      } catch (err) {
        this.props.history.push('/mtl-connect/result?page=requestOTP&error=request_otp_fail');
      }
      await this.setState({ submitEnable: true, loaded: true });
    }
  }

  verifyNumber = async (httpClient: HttpClient) => {
    const clientId = window.sessionStorage.getItem('clientId');
    const response = await httpClient.post('/register/verifyMobile', {
      mobile: this.state.telephone,
      clientId,
    });
    return response === true || response === 'true';
  }

  checkBlacklist = async (httpClient: HttpClient) => {
    const idcard = window.sessionStorage.getItem('idcard');
    const response = await httpClient.post('/register/checkblacklist', {
      sendTo: this.state.telephone,
      idcard,
    });
    if (response === true || response === 'true') {
      this.setState({ openDialog: true });
      return true;
    } else {
      return false;
    }
  }

  requestOtp = async (httpClient: HttpClient) => {
    const response = await httpClient.post('/register/sentOTP/mtl', {
      sendTo: this.state.telephone,
    });
    window.sessionStorage.setItem('telephone', this.state.telephone);
    if (response.MessageResult === "sent" || response.MessageResult === "") {
      window.sessionStorage.setItem('refCode', response.refCode);
      this.props.history.push('/mtl-connect/verifyOTP');
    } else {
      this.props.history.push('/mtl-connect/result?page=requestOTP&error=request_otp_fail');
    }
  }

  inputChange = async (e: any) => {
    const { name, value } = e.target;
    const state: any = {};
    state[name] = value;
    await this.setState(state);
    await this.validateInput();
  };

  validateInput = async () => {
    let { telephone, telephone_isValid } = this.state;
    telephone = telephone.replace(/_/g, '');
    if (telephone.length > 0) {
      telephone_isValid = telephone.length === 10 && telephone[0] === '0';
      await this.setState({ telephone_isValid, submitEnable: telephone_isValid });
    }
  }

  async componentDidMount() {
    const clientId = window.sessionStorage.getItem('clientId');
    const telephone = window.sessionStorage.getItem('telephone');
    if (telephone) {
      await this.setState({ telephone });
      await this.validateInput();
    }

    if (clientId) {
      await this.setState({ clientId });
    } else {
      this.props.history.push(`/mtl-connect/validator`);
    }
  }

  handleClose = async () => {
    if (this.state.openDialog === true) {
      const httpClient = new HttpClient();
      await this.requestOtp(httpClient);
    }
    this.setState({ openDialog: false });
  }

  handleMobileClose = async () => {
    this.setState({ openDialogMobile: false });
  }

  render() {
    if (this.state.clientId) {
      return (
        <React.Fragment>
          <link rel="stylesheet" type="text/css" href="/css/style.css" />
          <link rel="stylesheet" type="text/css" href="/css/responsive.css"/>
          <link rel="stylesheet" type="text/css" href="/css/MTLConnect.css"/>
           <Dialog
            open={this.state.openDialog}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"โปรดระวัง"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`หมายเลขโทรศัพท์ ${this.state.telephone} ของคุณได้ถูกลงทะเบียนว่าเป็นตัวแทนของบริษัทฯดังนั้น ขอสงวนสิทธิ์ในการสร้างบัญชีเพื่อใช้งาน MTL Mini Click ให้เจ้าของกรมธรรม์เท่านั้น ตามพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์หากมีการฝ่าฝืน มีโทษจำคุกไม่เกิน 6 เดือน หรือปรับไม่เกิน 10,000 บาท หรือ ทั้งจำทั้งปรับ`}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                ดำเนินการต่อ
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.openDialogMobile}
            onClose={this.handleMobileClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"ขออภัยค่ะ"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              หมายเลข {this.state.telephone} ไม่ตรงกับข้อมูลกรมธรรม์ในระบบ<br/><br/>
              กรุณาตรวจสอบใหม่อีกครั้งหรือโทร. 1766
               </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleMobileClose} color="primary">
                ปิด
              </Button>
            </DialogActions>
          </Dialog>
          <div
            id="wrap-loader"
            style={{ display: this.state.loaded ? "none" : "" }}
          >
            <div
              id="loader"
              style={{ display: this.state.loaded ? "none" : "block" }}
            ></div>
          </div>
          <div className="wrap-term connect-wrap">
            <div className="wrap-logo center">
              <div className="logo-mtl">
                <img src="/images/MTL-logo.png" alt="logo" onLoad={() => this.setState({ loaded: true })} />
              </div>
            </div>
            <div className="member-content" id="member-content">
              <div className="wrap-content connect-content">
                <h3 style={{ textAlign: 'center' }}>ลงชื่อเข้าใช้งาน สำหรับลูกค้า MTL</h3>
                <div className="register-smile smile-member-info connect-input">
                  <p style={{ color: '#555555', fontSize: '16px' }}>กรอกเบอร์โทรศัพท์เพื่อรับ OTP</p>
                  <div className="section_input member-input">
                    <InputMask
                      style={{ textAlign: 'center' }}
                      mask="9999999999"
                      placeholder="0xxxxxxxxx"
                      type="tel"
                      name="telephone"
                      id="telephone"
                      onChange={this.inputChange}
                      value={this.state.telephone}
                      pattern="\d*"
                      className={
                        this.state.telephone_isValid ? "" : "input-invalid"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.submitEnable
                  ? "btn-signin-fixed"
                  : "btn-signin-fixed btn-disabled"
              }
              onClick={this.submitProcess}
            >
              ถัดไป
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <div id="loader" style={{ display: "block" }}></div>;
    }
  }
}

export default RequestOTP;