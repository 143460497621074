import React, { Component } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { MTLConnect } from '../mtl-connect/MTLConnect';
import { MTLTerms } from '../mtl-terms/MTLTerms';
import MTLPayment from '../mtl-payment/MTLPayment';
import MTLPaymentQR from '../mtl-payment/pages/QRPayment';
import MTLPaymentBarcode from '../mtl-payment/pages/BarcodePayment';
import Smileclub from '../mtl-smileclub/MTLSmileClub';
import DownloadApp from '../mtl-download/DownloadApp';
import './App.css';
import MTLCertificate from '../mtl-certificate/MTLCertificate';
import MTLTaxConsent from '../mtl-consent/MTLTaxConsent';
import TrackingUrl from '../tracking-url/trackingUrl';

class App extends Component<any, any> {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route
            path="/mtl-connect"
            key="connect"
            render={(props) => <MTLConnect {...props} />}
          />
          <Route
            path="/mtl-terms"
            key="terms"
            render={(props) => <MTLTerms {...props} />}
          />
          <Route
            path="/mtl-payment"
            key="payment"
            render={(props) => <MTLPayment {...props} />}
          />
          <Route
            path="/smileclub"
            key="smileclub"
            render={(props) => <Smileclub {...props} />}
          />
          <Route
            path="/download-app"
            key="downloadApp"
            render={(props) => <DownloadApp {...props} />}
          />
          <Route
            path="/qrpayment"
            key="qrpayment"
            render={(props) => <MTLPaymentQR {...props} />}
          />
          <Route
            path="/barcodepayment"
            key="barcodepayment"
            render={(props) => <MTLPaymentBarcode {...props} />}
          />
          <Route
            path="/taxCertificate"
            key="taxCertificate"
            render={(props) => <MTLCertificate {...props} />}
          />
          <Route
            path="/taxConsent"
            key="taxConsent"
            render={(props) => <MTLTaxConsent {...props} />}
          ></Route>
          <Route
            path="/tracking-url"
            key="trackingUrl"
            render={(props) => <TrackingUrl {...props} />}
          ></Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
