import moment from 'moment';
import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { HttpClient } from '../../../services/HttpClient';
import '../MTLTaxConsent.css';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
export default class Check extends Component<any, any> {
  async componentDidUpdate(nextProps: any, nestState: any) {
    try {
      if (nextProps !== this.props && this.props.loadedToken === true) {
        const httpClient = new HttpClient();
        const response = await httpClient.post(
          '/tax-consent/policy-tax-deductible',
          { year: this.state.year },
        );
        if (response['tns:fld_result'] !== 'notfound_no_data') {
          this.setState({
            ...this.state,
            taxDeductibleList: Array.isArray(
              response['tns:fld_policy_tax_deductible_list'],
            )
              ? response['tns:fld_policy_tax_deductible_list']
              : Array(response['tns:fld_policy_tax_deductible_list']),
            totalPremiumForLife: response['tns:fld_total_premium_for_life'],
            totalPremiumForHealth: response['tns:fld_total_premium_for_health'],
            totalPremiumForPension:
              response['tns:fld_total_premium_for_pension'],
          });
        }
        await httpClient.post(`/customers/tracking`, {
          uid: sessionStorage.getItem('uid'),
          trackingKey: 'tax-consent-view',
          info: { taxConsent: 'check' },
        });
      }
    } catch (err) {
      console.log(err);
      window.location.href = `https://liff.line.me/${process.env.REACT_APP_LIFF_MTL_CONNECT}`;
    }
  }

  constructor(prop: any) {
    super(prop);
    this.state = {
      taxDeductibleList: [],
      totalPremiumForLife: '',
      totalPremiumForHealth: '',
      totalPremiumForPension: '',
      loadedToken: prop.loadedToken,
      year: new Date().getFullYear() + 542,
    };
  }

  handleSubmit = async () => {
    const httpClient = new HttpClient();
    await httpClient.post(`/customers/tracking`, {
      uid: sessionStorage.getItem('uid'),
      trackingKey: 'tax-consent-confirm',
      info: { taxConsent: 'check' },
    });
    this.props.history.push('/taxConsent/request');
  };

  render() {
    return (
      <div className="wrap-all">
        <div className="wrap-logo">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="logo-smile">
            <img src="/images/Graphic.svg" alt="smileLogo" />
          </div>
        </div>
        <div className="wrap-content" style={{ paddingBottom: '60px' }}>
          <h2 className="header">
            ตรวจสอบเบี้ยประกันภัย
            <br />
            สำหรับอ้างอิงใช้สิทธิยกเว้นภาษีเงินได้
          </h2>
          <div className="box-consent">
            <span className="text-consent">ปีภาษีที่ตรวจสอบ</span>
            <div className="border-box">{this.state.year}</div>
          </div>
          <p className="highlight">
            รวมเบี้ยประกันสำหรับอ้างอิงใช้สิทธิยกเว้นภาษีเงินได้
          </p>
          <div className="main-info">
            <span className="content-left">เบี้ยประกันชีวิต</span>
            <span className="content-right">
              {this.state.totalPremiumForLife === '.00'
                ? '0.00'
                : this.state.totalPremiumForLife}
            </span>
          </div>
          <div className="main-info">
            <span className="content-left">เบี้ยประกันสุขภาพ</span>
            <span className="content-right">
              {this.state.totalPremiumForHealth === '.00'
                ? '0.00'
                : this.state.totalPremiumForHealth}
            </span>
          </div>
          <div className="main-info">
            <span className="content-left">เบี้ยประกันชีวิตแบบบำนาญ</span>
            <span className="content-right">
              {this.state.totalPremiumForPension === '.00'
                ? '0.00'
                : this.state.totalPremiumForPension}
            </span>
          </div>
          <div className="box-consent">
            <div className="submit-btn" onClick={this.handleSubmit}>
              ดำเนินการแจ้งความประสงค์ขอใช้สิทธิ์
            </div>
          </div>
          <div className="collapse-consent">
            {this.state.taxDeductibleList.length > 0 &&
              this.state.taxDeductibleList.map((item: any, index: number) => {
                return (
                  <Collapsible
                    key={index}
                    trigger={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{ width: '4%' }}
                          src={
                            item['tns:tax_consent_last_status'] === 'Y'
                              ? '/images/check-circle.svg'
                              : '/images/x-circle.svg'
                          }
                          alt="status"
                        ></img>
                        <span
                          className="text-content"
                          style={{ marginLeft: '3%' }}
                        >
                          กรมธรรม์ประกันภัยเลขที่
                        </span>
                        <span style={{ marginLeft: '7%', color: '#2B3B8B' }}>
                          {item['tns:policy_number']}
                        </span>
                      </div>
                    }
                  >
                    <div
                      style={{ margin: '0px', width: '100%' }}
                      className="main-info"
                    >
                      <span className="content-left">แบบประกันภัย</span>
                      <span
                        style={{ color: '#2B3B8B' }}
                        className="content-right"
                      >
                        {item['tns:plan_name_thai']}
                      </span>
                    </div>
                    <div className="text-head">
                      เบี้ยประกันที่ใช้สิทธิยกเว้นภาษีเงินได้
                    </div>
                    <div
                      style={{ margin: '5% 5% 5% 5%', width: '82%' }}
                      className="main-info"
                    >
                      <span className="content-left">เบี้ยประกันชีวิต</span>
                      <span
                        style={{ width: '75px', color: '#2B3B8B' }}
                        className="content-right"
                      >
                        {item['tns:premium_for_life'] === '.00'
                          ? '0.00'
                          : item['tns:premium_for_life']}
                      </span>
                    </div>
                    <div
                      style={{ margin: '5% 5% 5% 5%', width: '82%' }}
                      className="main-info"
                    >
                      <span className="content-left">เบี้ยประกันสุขภาพ</span>
                      <span
                        style={{ width: '75px', color: '#2B3B8B' }}
                        className="content-right"
                      >
                        {item['tns:premium_for_health'] === '.00'
                          ? '0.00'
                          : item['tns:premium_for_health']}
                      </span>
                    </div>
                    <div
                      style={{ margin: '5% 5% 5% 5%', width: '82%' }}
                      className="main-info"
                    >
                      <span className="content-left">
                        เบี้ยประกันชีวิตแบบบำนาญ
                      </span>
                      <span
                        style={{ width: '75px', color: '#2B3B8B' }}
                        className="content-right"
                      >
                        {item['tns:premium_for_pension'] === '.00'
                          ? '0.00'
                          : item['tns:premium_for_pension']}
                      </span>
                    </div>
                    <div className="text-head-2">
                      สถานะการแจ้งความประสงค์ขอใช้สิทธิยกเว้นภาษีเงินได้
                    </div>
                    <div
                      className="text-content-2"
                      style={{ margin: '3% 0px' }}
                    >
                      <span>สถานะ</span>
                      <img
                        src={
                          item['tns:tax_consent_last_status'] === 'Y'
                            ? '/images/check-circle.svg'
                            : '/images/x-circle.svg'
                        }
                        style={{ width: '5%', margin: '1% 3% -5px 3%' }}
                        alt="status"
                      ></img>
                      <span>แจ้งความประสงค์ใช้สิทธิวันที่</span>
                      <span style={{ marginLeft: '2%', color: '#2b3b8b' }}>
                        {item['tns:tax_consent_last_updated_date_time']
                          ? moment(
                              item[
                                'tns:tax_consent_last_updated_date_time'
                              ].split(' ')[0],
                              'YYYYMMDD',
                            ).format('DD/MM/YYYY')
                          : '-'}
                      </span>
                    </div>
                  </Collapsible>
                );
              })}
          </div>
          <div className="ps-content">
            หมายเหตุ
            <br />
            1. กรณีที่ท่านไม่ได้แจ้งความประสงค์ บริษัทฯ
            จะถือว่าท่านไม่ให้ความยินยอม
            ในการนำส่งและเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อ
            กรมสรรพากรท่านจะไม่สามารถนำเบี้ยประกันภัยดังกล่าวไปใช้สิทธิขอยกเว้นภาษีได้
            <br />
            2. กรณีที่ท่านเคยแจ้งความประสงค์ไว้แล้ว บริษัทฯ จะถือว่าการแจ้งความ
            ประสงค์ในครั้งนี้ เป็นการแจ้งความประสงค์ล่าสุดของท่าน
            <br />
            3. สัญญาประกันภัยข้างต้น เป็นสัญญาประกันภัยที่สามารถนำ
            เบี้ยประกันภัยไปใช้สิทธิยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร
            แต่ไม่รวมถึงสัญญาประกันภัยที่อยู่ระหว่างการพิจารณารับประกัน
          </div>
        </div>
      </div>
    );
  }
}
