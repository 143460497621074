import React, { Component } from "react";
import { Terms } from './terms';
import { HttpClient } from '../../../services/HttpClient';
import Axios from "axios";

class Consent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      agree: {
        consent_agree: false,
        MTL_CONSENT_1_ID: false,
        MTL_CONSENT_2_ID: false,
      },
      updateConsent: false,
      loaded: true,
      menu: "",
      showMore: false,
      termsAgree: false,
    };
  }

  async componentDidMount() {
    this.setState({ loaded: false });
    const urlParams = new URLSearchParams(window.location.search);
    const updateConsent = urlParams.get('updateConsent');
    const menu = urlParams.get('menu');
    const key = urlParams.get('key');
    const uid = urlParams.get('uid');
    if (updateConsent) {
      this.setState({ updateConsent: updateConsent === 'true', menu });
      if (updateConsent === 'true') {
        await this.updateConsentOnload(key, uid);
      }
    }
    const agree = window.sessionStorage.getItem("consent_agree");
    if (agree) this.setState({ agree: JSON.parse(agree) });

    const termsAgree = window.sessionStorage.getItem('termsAgree');
    if (termsAgree) this.setState({ termsAgree: termsAgree === 'true' });
    this.setState({ loaded: true });
    this.gotoTopPage();
  }

  handleCheckbox = (event: any) => {
    const agree = this.state.agree;
    agree[event.target.name] = event.target.checked;
    if (event.target.name === 'consent_agree') {
      agree.MTL_CONSENT_1_ID = event.target.checked;
      agree.MTL_CONSENT_2_ID = event.target.checked;
    } else if (agree.MTL_CONSENT_1_ID && agree.MTL_CONSENT_2_ID) {
      agree.consent_agree = true;
    } else {
      agree.consent_agree = false;
    }
    this.setState({ agree });
  };

  handleCheckboxTerms = (event: any) => {
    this.setState({ termsAgree: event.target.checked });
  };

  updateConsentOnload = async (key: any, uid: any) => {
    const { agree } = this.state;
    const API_URL = (process.env.REACT_APP_API_URL as string) || "/api";
    try {
      const response = await Axios.post(`${API_URL}/register/updateConsentMTL`, {
        uid,
        consent: agree,
      }, {
        headers: {
          Authorization: "bearer " + key
        }
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  updateConsent = async () => {
    const { agree } = this.state;
    const httpClient = new HttpClient();
    const liff = window.liff;
    const profile = await liff.getProfile();
    try {
      const response = await httpClient.post('/register/updateConsentMTL', {
        uid: profile.userId,
        consent: agree,
      });
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  }

  handleSubmit = async (event: any) => {
    const { agree, updateConsent, menu } = this.state;
    if (this.state.termsAgree) {
      if (!updateConsent) {
        window.sessionStorage.setItem("termsAgree", this.state.termsAgree);
        window.sessionStorage.setItem("consent_agree", JSON.stringify(agree));
        this.props.history.push("/mtl-connect/consent");
      }
    }

    if (updateConsent) {
      const liff = window.liff;
      this.setState({ loaded: false });
      await this.updateConsent();
      await liff.sendMessages([
        {
          type: 'text',
          text: menu
        }
      ]);
      liff.closeWindow();
      this.setState({ loaded: true });
    }
  };

  gotoTopPage = () => {
    //Go to top page
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  render() {
    let btnClass = this.state.termsAgree || this.state.updateConsent ? "btn-signin-in-fixed" : "btn-signin-in-fixed btn-disabled";
    return (
      <React.Fragment>
        <link rel="stylesheet" type="text/css" href="/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/css/responsive.css" />
        <link rel="stylesheet" type="text/css" href="/css/MTLConnect.css" />
        <div
          id="loader"
          style={{ display: this.state.loaded ? "none" : "block" }}
        ></div>
        <div
          className="wrap-term animate-fade"
          style={{ display: this.state.loaded ? "block" : "none" }}
        >
          <div className="wrap-logo center">
            <div className="logo-mtl">
              <img src="/images/MTL-logo.png" alt="logo" onLoad={() => this.setState({ loaded: true })} />
            </div>
          </div>
          <div className="wrap-content term-content" style={{ paddingBottom: '120px' }}>
            {(!this.state.updateConsent) ? <Terms /> : null}
          </div>

          <div className="submit-container">
            <div className="section_input condition-section" style={{width: '90%', margin: '3% auto'}}>
              <div className="container">
                <label htmlFor="consent_agree_terms">ยอมรับข้อกำหนดและเงื่อนไขการใช้บริการ</label>
                <input
                  type="checkbox"
                  name="consent_agree_terms"
                  id="consent_agree_terms"
                  onChange={this.handleCheckboxTerms}
                  checked={this.state.termsAgree}
                />
                <label className="checkmark" htmlFor="consent_agree_terms"></label>
              </div>
            </div>
            <div className={btnClass} onClick={this.handleSubmit} >
              {this.state.updateConsent ? 'ยินยอม' : 'ถัดไป'}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Consent;
