import React, { Component } from "react";
import InputMask from "react-input-mask";

class Register extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      inputType: "sms",
      telephone: "",
      email: "",
      telephone_isValid: true,
      email_isValid: true,
      submitEnable: false
    };
  }

  componentDidMount() {
    const agreeStr = window.sessionStorage.getItem("consent_agree");
    const customerDetailStr = window.sessionStorage.getItem('customerDetail');
    if (!agreeStr) {
      this.props.history.push(`/smileclub/consent`);
    } 
    if (customerDetailStr) {
      const customerDetail = JSON.parse(customerDetailStr);
      let submitEnable = true;
      if (customerDetail.email === '') submitEnable = false;
      if (customerDetail.mobile === '') submitEnable = false;
      this.setState({ email: customerDetail.email, telephone: customerDetail.mobile, submitEnable });
    }
  }

  switchInput = async () => {
    let { inputType } = this.state;
    if (inputType === "sms") {
      inputType = "email";
    } else {
      inputType = "sms";
    }
    await this.setState({ inputType });
    await this.validateInput();
  };

  inputChange = async (e: any) => {
    const { name, value } = e.target;
    const state: any = {};
    state[name] = value;
    await this.setState(state);
    await this.validateInput();
  };

  validateInput = async () => {
    let submitEnable = false;
    let {
      telephone,
      telephone_isValid,
      email,
      email_isValid,
      inputType
    } = this.state;
    if (inputType === "sms") {
      telephone = telephone.replace(/_/g, "");
      telephone_isValid = !(telephone.length > 0 && telephone.length < 10);
      submitEnable = telephone_isValid && telephone.length;
    } else {
      if (email.length) {
        // eslint-disable-next-line
        email_isValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          email
        );
        submitEnable = email_isValid && email.length;
      }
    }
    await this.setState({ email_isValid, telephone_isValid, submitEnable });
  };

  submitHandler = () => {
    if (this.state.submitEnable) {
      const data =
        this.state.inputType === "sms"
          ? { sms: this.state.telephone }
          : { email: this.state.email };
      this.props.history.push("/smileclub/OTP", data);
    }
  };

  render() {
    return (
      <div className="wrap-all">
        <div className="wrap-logo">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="logo-smile">
            <img src="/images/smileClub-logo.png" alt="smileLogo" />
          </div>
        </div>
        <div className="wrap-content" style={{paddingBottom: '60px'}}> 
          <div className="smile-club-banner">
            <img src="/images/smile-club.png" alt="smileclub" />
          </div>
          <h2>
            ลงทะเบียนสมาชิก <br /> <b>เมืองไทยสไมล์คลับ</b>
          </h2>
          <div className="register-smile">
            {this.state.inputType === "email" ? (
              <p>อีเมล (Email.)</p>
            ) : (
              <p>เบอร์โทรศัพท์ (Mobile No.)</p>
            )}
            <div
              className="section_input"
              style={{
                display: this.state.inputType === "email" ? "" : "none"
              }}
            >
              <InputMask
                // eslint-disable-next-line
                mask={[/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/]}
                placeholder="email@example.com"
                type="email"
                name="email"
                id="email"
                onChange={this.inputChange}
                value={this.state.email}
                className={this.state.email_isValid ? "" : "input-invalid"}
              />
            </div>
            <div
              className="section_input"
              style={{
                display: this.state.inputType === "sms" ? "" : "none"
              }}
            >
              <InputMask
                mask="9999999999"
                placeholder="091234xxxx"
                type="tel"
                name="telephone"
                id="telephone"
                onChange={this.inputChange}
                value={this.state.telephone}
                pattern="\d*"
                className={this.state.telephone_isValid ? "" : "input-invalid"}
              />
            </div>
            <p className="info">
              กรณีเบอร์โทรศัพท์มือถือหรืออีเมลของท่านไม่ถูกต้อง
              <br />
              กรุณาติดต่อ 1766 กด 4 (ในเวลาทำการ)
            </p>
            <div className="select-btn" onClick={this.switchInput}>
              <div
                className="select-regis"
                style={{
                  display: this.state.inputType === "email" ? "none" : ""
                }}
              >
                เข้าใช้งานด้วย Email
                <div className="icon">
                  <img src="/images/mail-icon.png" alt="mail" />
                </div>
              </div>
              <div
                className="select-regis select-phone-btn"
                style={{
                  display: this.state.inputType === "sms" ? "none" : ""
                }}
              >
                เข้าใช้งานด้วยเบอร์โทรศัพท์
                <div className="icon phone-icon">
                  <img src="/images/phone-icon.png" alt="phoneIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.submitEnable ? "btn-signin-fixed" : "btn-signin-fixed btn-disabled"
          }
          onClick={this.submitHandler}
        >
          ลงชื่อเข้าใช้งาน
        </div>
      </div>
    );
  }
}

export default Register;
