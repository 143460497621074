import React, { Component } from 'react';
import { HttpClient } from "../../../services/HttpClient";
import Axios from "axios";

const TERM_2 = "เนื่องด้วย พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 กรณีผู้เยาว์ที่อายุมากกว่า 10 ปี แต่ไม่ถึง 20 ปี ต้องได้รับความยินยอมจากทั้งตัวผู้สมัครสมาชิก และบิดา/มารดา/หรือผู้ปกครองโดยชอบด้วยกฏหมาย หากท่านต้องการสมัครสมาชิกเมืองไทยสไมล์คลับผ่านช่องทางออนไลน์ กรุณาติดต่อศูนย์บริการลูกค้าเมืองไทยประกันชีวิตเพื่อสมัครสมาชิกฯ";

class Consent extends Component<any, any> {
  state = {
    customerDetail: {
      age: 10,
      isSmileclubMember: 'N',
    },
    agree: {
      consent_agree: false,
      SMILECLUB_CONSENT_1_ID: false,
      SMILECLUB_CONSENT_2_ID: false,
      SMILECLUB_CONSENT_3_ID: false,
      SMILECLUB_CONSENT_4_ID: false,
    },
    clientNumber: '',
    consentGroup: 2,
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get('key');
    const agree = window.sessionStorage.getItem("consent_agree");
    if (agree) this.setState({ agree: JSON.parse(agree) });

    const customerDetailStr = sessionStorage.getItem('customerDetail');
    const clientNumberStr = sessionStorage.getItem('clientNumber');
    let { consentGroup, customerDetail, clientNumber } = this.state;

    if (customerDetailStr) {
      customerDetail = JSON.parse(customerDetailStr);
      if (customerDetail.isSmileclubMember === 'Y' && key ) {
        const API_URL = (process.env.REACT_APP_API_URL as string) || "/api";
        const uid = window.sessionStorage.getItem('uid')
        const { customerDetail, agree } = this.state;
        try {
          const response = await Axios.post(`${API_URL}/register/updateConsentSMC`,
          { customer: customerDetail,
            agree,
            uid,
            lastGroup: consentGroup 
          }, 
          {
            headers: {
              Authorization: "bearer " + key
            }
          });
          console.log(response);
        } catch (err) {
          console.log(err);
        }
      }
    }
    if (clientNumberStr) {
      clientNumber = clientNumberStr;
    }

    const { age } = customerDetail;
    if (age <= 10) {
      consentGroup = 1;
    } else if (age > 10 && age < 20) {
      consentGroup = 2;
    } else {
      consentGroup = 3;
    }
    this.setState({ customerDetail, clientNumber, consentGroup });

    if ((customerDetail.isSmileclubMember === 'N' || customerDetail.isSmileclubMember === 'W') && key) {
      const agreePass = {
        consent_agree: true,
        SMILECLUB_CONSENT_1_ID: false,
        SMILECLUB_CONSENT_2_ID: false,
        SMILECLUB_CONSENT_3_ID: false,
        SMILECLUB_CONSENT_4_ID: false,
      };
      window.sessionStorage.setItem("consent_agree", JSON.stringify(agreePass));
      window.sessionStorage.setItem("last_group", consentGroup.toString());
      this.props.history.push("/smileclub/register-form");
    }
  }

  async componentDidUpdate(preProps: any, newState: any) {
    if (preProps !== this.props && this.props.loadedToken === true) {
      const agree = window.sessionStorage.getItem("consent_agree");
      if (agree) this.setState({ agree: JSON.parse(agree) });

      const customerDetailStr = sessionStorage.getItem('customerDetail');
      const clientNumberStr = sessionStorage.getItem('clientNumber');
      let { consentGroup, customerDetail, clientNumber } = this.state;

      if (customerDetailStr) {
        customerDetail = JSON.parse(customerDetailStr);
      }
      if (clientNumberStr) {
        clientNumber = clientNumberStr;
      }

      const { age } = customerDetail;
      if (age <= 10) {
        consentGroup = 1;
      } else if (age > 10 && age < 20) {
        consentGroup = 2;
      } else {
        consentGroup = 3;
      }
      this.setState({ customerDetail, clientNumber, consentGroup });

      if (customerDetail.isSmileclubMember === 'N') {
        const agreePass = {
          consent_agree: true,
          SMILECLUB_CONSENT_1_ID: false,
          SMILECLUB_CONSENT_2_ID: false,
          SMILECLUB_CONSENT_3_ID: false,
          SMILECLUB_CONSENT_4_ID: false,
        };
        window.sessionStorage.setItem("consent_agree", JSON.stringify(agreePass));
        window.sessionStorage.setItem("last_group", consentGroup.toString());
        this.props.history.push("/smileclub/register-form");
      }
    }
  }

  handleCheckbox = (event: any) => {
    const agree: any = this.state.agree;
    agree[event.target.name] = event.target.checked;
    if (event.target.name === 'consent_agree' && this.state.consentGroup === 1) {
      agree.SMILECLUB_CONSENT_1_ID = event.target.checked;
      agree.SMILECLUB_CONSENT_2_ID = event.target.checked;
    }
    if (event.target.name === 'consent_agree' && this.state.consentGroup === 3) {
      agree.SMILECLUB_CONSENT_3_ID = event.target.checked;
      agree.SMILECLUB_CONSENT_4_ID = event.target.checked;
    }
    if (agree.SMILECLUB_CONSENT_1_ID && agree.SMILECLUB_CONSENT_2_ID) {
      agree.consent_agree = true;
    } else if (this.state.consentGroup === 1) {
      agree.consent_agree = false;
    }

    if (agree.SMILECLUB_CONSENT_3_ID && agree.SMILECLUB_CONSENT_4_ID) {
      agree.consent_agree = true;
    } else if (this.state.consentGroup === 3) {
      agree.consent_agree = false;
    }
    this.setState({ agree });
  };

  handleSubmit = async () => {
    const liff = window.liff;
    const { agree, consentGroup, customerDetail } = this.state;
    if (consentGroup === 2) {
      setTimeout(() => {
        liff.closeWindow();
      }, 300);
    } else {
      if (customerDetail.isSmileclubMember === 'Y') {
        const httpClient = new HttpClient();
        this.setState({loaded: false});
        const uid = window.sessionStorage.getItem('uid')
        const { customerDetail, agree } = this.state;
        const result = await httpClient.post(
          "/register/updateConsentSMC",
          { customer: customerDetail, agree, uid, lastGroup: consentGroup }
        );
        console.log(result);
        await liff.sendMessages([
          {
            type:'text',
            text: 'ตรวจสอบคะแนน'
          }
        ]);
        this.setState({loaded: true});
        liff.closeWindow();
      } else {
        window.sessionStorage.setItem("consent_agree", JSON.stringify(agree));
        window.sessionStorage.setItem("last_group", consentGroup.toString());
        this.props.history.push("/smileclub/register-form");
      }
    }
  };

  consent1 = () => {
    const { consent_agree, SMILECLUB_CONSENT_1_ID, SMILECLUB_CONSENT_2_ID } = this.state.agree;
    return <React.Fragment>
      <div className="section_input condition-section">
        <div className="container">
          <label htmlFor="consent_agree" style={{ fontSize: '18px' }}>ข้าพเจ้ายินยอมให้บริษัทฯประมวลผลข้อมูลส่วนบุคคลของผู้อยู่ในอำนาจปกครองของข้าพเจ้าตามวัตถุประสงค์ที่ระบุด้านล่างนี้ทั้งหมด</label>
          <input
            type="checkbox"
            name="consent_agree"
            id="consent_agree"
            onChange={this.handleCheckbox}
            checked={consent_agree}
          />
          <label className="checkmark" htmlFor="consent_agree"></label>
        </div>
      </div>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <div className="section_input condition-section">
            <div className="container">
              <label style={{ fontSize: '18px' }} >ข้าพเจ้ายินยอมให้บริษัทฯเก็บรวบรวม ใช้ ประมวลผลข้อมูลของเจ้าของข้อมูลส่วนบุคคลผู้อยู่ในอำนาจปกครองของข้าพเจ้าที่ได้ให้ไว้กับบริษัทฯ หรือที่อยู่ในความครอบครองของบริษัทฯ ในการประกอบใบสมัครสมาชิกเมืองไทยสไมล์คลับ นี้ รวมถึงข้อมูลที่จะให้เพิ่มเติมแก่บริษัทฯ ในอนาคต รวมถึงยินยอมให้เปิดเผยข้อมูลดังกล่าวให้แก่บุคคลหรือนิติบุคคลอื่น*ที่เกี่ยวข้องตาม<a href="https://www.muangthai.co.th/th/privacy-policy">นโยบายความเป็นส่วนตัว</a>ตามที่บริษัทฯ เห็นว่าจะเป็นการเพิ่มประสิทธิภาพการให้บริการ หรือการนำเสนอผลิตภัณฑ์ และข้อเสนอพิเศษอื่น หรือเพื่อการอื่นใดที่บริษัทฯ เห็นว่าจะเป็นประโยชน์แก่เจ้าของข้อมูลส่วนบุคคลผู้อยู่ในอำนาจปกครองของข้าพเจ้าได้</label>
              <input
                type="checkbox"
                name="SMILECLUB_CONSENT_1_ID"
                id="SMILECLUB_CONSENT_1_ID"
                onChange={this.handleCheckbox}
                checked={SMILECLUB_CONSENT_1_ID}
              />
              <label className="checkmark" htmlFor="SMILECLUB_CONSENT_1_ID"></label>
            </div>
          </div>
        </li>
        <li>
          <div className="section_input condition-section">
            <div className="container">
              <label style={{ fontSize: '18px' }} >ข้าพเจ้ายินยอมให้บริษัทฯ บุคคลหรือนิติบุคคลอื่น*ที่เกี่ยวข้องตาม<a href="https://www.muangthai.co.th/th/privacy-policy">นโยบายความเป็นส่วนตัว</a>ของบริษัทฯ จัดส่งข้อมูลข่าวสารกิจกรรม ผลิตภัณฑ์ บริการและสิทธิประโยชน์ตามที่อยู่ หรือผ่านช่องทางการสื่อสารต่าง ๆ เช่น อีเมล SMS ให้กับเจ้าของข้อมูลส่วนบุคคลผู้อยู่ในอำนาจปกครองของข้าพเจ้าตามที่ข้าพเจ้าได้ให้ข้อมูลไว้ </label>
              <input
                type="checkbox"
                name="SMILECLUB_CONSENT_2_ID"
                id="SMILECLUB_CONSENT_2_ID"
                onChange={this.handleCheckbox}
                checked={SMILECLUB_CONSENT_2_ID}
              />
              <label className="checkmark" htmlFor="SMILECLUB_CONSENT_2_ID"></label>
            </div>
          </div>
        </li>
      </ul>
      <p style={{ fontSize: '18px' }}>หากบริษัทฯ มีการแก้ไขเพิ่มเติมบุคคลหรือนิติบุคคลอื่นตาม<a href="https://www.muangthai.co.th/th/privacy-policy">นโยบายความเป็นส่วนตัว</a> โดยบริษัทฯ จะแจ้งให้ทราบล่วงหน้าในเว็บไซต์ <a href="http://muangthai.co.th" style={{ textDecoration: 'underline' }}>.muangthai.co.th</a> หากบริษัทฯ ไม่ได้รับการปฏิเสธในระยะเวลา 30 วัน นับแต่วันที่แจ้งให้ทราบ ให้ถือว่าเป็นการให้ความยินยอมแก่บริษัทฯ ในการนำส่งข้อมูลให้แก่บุคคลหรือนิติบุคคลอื่นที่แก้ไขเพิ่มเติมตามที่บริษัทฯ ได้แจ้งไป </p>
      <p style={{ fontSize: '18px' }}>ข้าพเจ้ายอมรับว่าในการใช้บริการที่เกี่ยวข้องกับ เมืองไทยสไมล์คลับ ตามช่องทางต่างๆ เช่น เว็บไซต์หรือแอปพลิเคชัน บริษัทฯ จะเก็บข้อมูลส่วนบุคคลอื่นๆ จากข้าพเจ้าและ/หรือเจ้าของข้อมูลส่วนบุคคลผู้อยู่ในอำนาจปกครองของข้าพเจ้าได้โดยปฏิบัติตามกฎหมาย</p>
      <p style={{ fontSize: '18px' }}>ในกรณีที่มีการเปลี่ยนแปลงข้อมูลการติดต่อ ข้าพเจ้าจะแจ้งบริษัทฯ ที่โทร 1766 เมืองไทยประกันชีวิต เพื่อให้บริษัทฯ สามารถติดต่อตามช่องทางการติดต่อที่ถูกต้องต่อไป</p>
    </React.Fragment>;
  }


  consent3 = () => {
    const { consent_agree, SMILECLUB_CONSENT_3_ID, SMILECLUB_CONSENT_4_ID } = this.state.agree;
    return <React.Fragment>
      <div className="section_input condition-section">
        <div className="container">
          <label htmlFor="consent_agree" style={{ fontSize: '18px' }}>ข้าพเจ้ายินยอมให้บริษัทฯประมวลผลข้อมูลส่วนบุคคลตามวัตถุประสงค์ที่ระบุด้านล่างนี้ทั้งหมด</label>
          <input
            type="checkbox"
            name="consent_agree"
            id="consent_agree"
            onChange={this.handleCheckbox}
            checked={consent_agree}
          />
          <label className="checkmark" htmlFor="consent_agree"></label>
        </div>
      </div>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <div className="section_input condition-section">
            <div className="container">
              <label style={{ fontSize: '18px' }}>ข้าพเจ้ายินยอมให้บริษัทฯเก็บรวบรวม ใช้ ประมวลผล ข้อมูลของข้าพเจ้าที่ได้ให้ไว้กับบริษัทฯ หรือที่อยู่ในความครอบครองของบริษัทฯ ในการประกอบใบสมัครสมาชิกเมืองไทยสไมล์คลับ นี้ รวมถึงข้อมูลที่จะให้เพิ่มเติมแก่บริษัทฯ ในอนาคต รวมถึงยินยอมให้เปิดเผยข้อมูลดังกล่าวให้แก่บุคคลหรือนิติบุคคลอื่น*ที่เกี่ยวข้องตาม<a href="https://www.muangthai.co.th/th/privacy-policy">นโยบายความเป็นส่วนตัว</a>ตามที่บริษัทฯ เห็นว่าจะเป็นการเพิ่มประสิทธิภาพการให้บริการ หรือการนำเสนอผลิตภัณฑ์ และข้อเสนอพิเศษอื่น หรือเพื่อการอื่นใดที่บริษัทฯ เห็นว่าจะเป็นประโยชน์แก่ข้าพเจ้าได้</label>
              <input
                type="checkbox"
                name="SMILECLUB_CONSENT_3_ID"
                id="SMILECLUB_CONSENT_3_ID"
                onChange={this.handleCheckbox}
                checked={SMILECLUB_CONSENT_3_ID}
              />
              <label className="checkmark" htmlFor="SMILECLUB_CONSENT_3_ID"></label>
            </div>
          </div>
        </li>
        <li>
          <div className="section_input condition-section">
            <div className="container">
              <label style={{ fontSize: '18px' }} >ข้าพเจ้ายินยอมให้บริษัทฯ บุคคลหรือนิติบุคคลอื่น*ที่เกี่ยวข้องตาม<a href="https://www.muangthai.co.th/th/privacy-policy">นโยบายความเป็นส่วนตัว</a>ของบริษัทฯ จัดส่งข้อมูลข่าวสารกิจกรรม ผลิตภัณฑ์ บริการและสิทธิประโยชน์ตามที่อยู่ หรือผ่านช่องทางการสื่อสารต่าง ๆ เช่น อีเมล SMS ให้กับข้าพเจ้าตามที่ข้าพเจ้าได้ให้ข้อมูลไว้ </label>
              <input
                type="checkbox"
                name="SMILECLUB_CONSENT_4_ID"
                id="SMILECLUB_CONSENT_4_ID"
                onChange={this.handleCheckbox}
                checked={SMILECLUB_CONSENT_4_ID}
              />
              <label className="checkmark" htmlFor="SMILECLUB_CONSENT_4_ID"></label>
            </div>
          </div>
        </li>
      </ul>
    </React.Fragment>;
  }



  render() {
    const { customerDetail } = this.state;
    return (
      <React.Fragment>
        <div
          id="wrap-loader"
          style={{ display: this.props.loadedToken ? "none" : "" }}
        >
          <div
            id="loader"
            style={{ display: this.props.loadedToken ? "none" : "block" }}
          ></div>
        </div>
        <div className="wrap-all" style={{ display: this.props.loadedToken ? "block" : "none" }}>
          <div className="wrap-logo center">
            <div className="logo-smile">
              <img src="/images/smileClub-logo.png" alt="smileLogo" />
            </div>
          </div>
          {(this.state.consentGroup === 2) ?
            <div className="wrap-content smile-consent" style={{paddingBottom: '60px'}}><p>&ldquo;{TERM_2}&ldquo;</p></div>
            :
            <div className="wrap-content smile-consent" style={{paddingBottom: '60px'}}>
              <h3>การให้ความยินยอมสมาชิก<span style={{ whiteSpace: 'nowrap' }}>เมืองไทยสไมล์คลับ</span></h3>
              <br />
              <p style={{ textIndent: '0.25cm' }}>บริษัทเมืองไทยประกันชีวิตจำกัด (มหาชน) (&ldquo;บริษัทฯ&rdquo;) อยู่ภายใต้บทบังคับตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ. 2562 ข้อมูลที่เก็บรวบรวมใช้ประมวลผลและเปิดเผยเพื่อวัตถุประสงค์ในการสมัครสมาชิกเมืองไทยเมืองไทยสไมล์คลับจึงตกอยู่ภายใต้บทบังคับของพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ. 2562 ทั้งนี้หากท่านมีข้อสงสัยเกี่ยวกับวิธีและขั้นตอนที่บริษัทใช้ข้อมูลส่วนบุคคลของท่านและ/หรือเจ้าของข้อมูลส่วนบุคคลหรือเกี่ยวกับสิทธิของท่านและ/หรือเจ้าของข้อมูลส่วนบุคคลท่านสามารถตรวจสอบข้อมูลเพิ่มเติมได้ที่<a href="https://www.muangthai.co.th/th/privacy-policy">นโยบายความเป็นส่วนตัว</a>ที่เว็บไซต์&nbsp;<a href="http://muangthai.co.th" style={{ textDecoration: 'underline' }}>muangthai.co.th</a></p>
              <br />
              <p style={{ textIndent: '0.25cm' }}>ทั้งนี้บริษัทสงวนสิทธิ์ในการอ้างสิทธิตามสัญญาหรือตามกฎหมายในการเก็บรวบรวมใช้หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ในการสมัครสมาชิกเมืองไทยสไมล์คลับและการให้บริการ MTL Click Application แก่ท่านและ/หรือเจ้าของข้อมูลส่วนบุคคล</p>
              <br />
              <p style={{ textIndent: '0.25cm' }}>อนึ่งบริษัทฯอาจจะไม่สามารถดำเนินการสมัครสมาชิกเมืองไทยสไมล์คลับได้จนแล้วเสร็จโดยปราศจากความยินยอมอย่างใดอย่างหนึ่งหรือทั้งหมดของท่านทำให้ท่านและ/หรือเจ้าของข้อมูลส่วนบุคคลไม่สามารถใช้บริการบางประเภทได้หรืออาจไม่ได้รับการแจ้งสิทธิประโยชน์บางประการจากบริษัท</p>
              <br />
              {(this.state.consentGroup === 1) ? this.consent1() : this.consent3()}
            </div>
          }

          <div
            onClick={this.handleSubmit}
            className={'btn-signin-fixed'}
          >
            {(customerDetail.isSmileclubMember === 'Y') ? 'ยอมรับ' : (this.state.consentGroup === 2) ? 'ปิด' : 'ถัดไป'}
          </div>
        </div>
      </React.Fragment>

    );
  }
}

export default Consent;