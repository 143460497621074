import React from 'react';
import '../payment/style.css';
import '../payment/responsive.css';

export default class MTLPaymentQR extends React.Component<any, any> {
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div className="wrap-all">
        <div className="wrap-content wrap-barcode payment-wrap qr-wrap">
          <div
            className="barcode-btn payment-back-btn"
            onClick={() => this.goBack()}
          >
            กลับหน้าชำระเงิน
          </div>
          <h3>ช่องทางการชำระเงิน Mobile Banking </h3>
          <h4>
            บริการชำระบิลข้ามธนาคาร
            <br />
            (Cross Bank Bill Payment)
          </h4>
          <div className="payment-content-wrap payment-content-qr">
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-K-PLus.png" alt="K-Plus" />
                <div className="pay-info qr-pay-info">
                  <p>K PLUS</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-SCB.png" alt="SCB" />
                <div className="pay-info qr-pay-info">
                  <p>SCB Easy</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-Buakuang.png" alt="Bualuang" />
                <div className="pay-info qr-pay-info">
                  <p>Bualuang mBanking</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-KTB.png" alt="KTB" />
                <div className="pay-info qr-pay-info">
                  <p>KTB Next</p>
                </div>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap payment-content-qr">
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-KMA.png" alt="KMA" />
                <div className="pay-info qr-pay-info">
                  <p>KMA</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-TTB.png" alt="TTB" />
                <div className="pay-info qr-pay-info">
                  <p>TTB</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-LH.png" alt="LH" />
                <div className="pay-info qr-pay-info">
                  <p>LH Bank MChoice</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-UOB.png" alt="UOB" />
                <div className="pay-info qr-pay-info">
                  <p>UOB MIGHTY</p>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-content-wrap payment-content-qr">
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-CIMB.png" alt="CIMB" />
                <div className="pay-info qr-pay-info">
                  <p>CIMB THAI</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-MYMO.png" alt="GSB" />
                <div className="pay-info qr-pay-info">
                  <p>MyMo by GSB</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-KK.png" alt="KK" />
                <div className="pay-info qr-pay-info">
                  <p>KK e-Banking</p>
                </div>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-ICBC.png" alt="ICBC" />
                <div className="pay-info qr-pay-info">
                  <p>ICBC</p>
                </div>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap payment-content-qr">
            <div className="payment-content qr-pay-last">
              <div className="pay-logo qr-pay-logo">
                <img src="images/qr-TISCO.png" alt="TISCO" />
                <div className="pay-info qr-pay-info">
                  <p>TISCO</p>
                </div>
              </div>
            </div>
          </div>

          <p className="pay-note qr-note">
            หมายเหตุ: ท่านสามารถชำระเงินได้ไม่เกิน 1,000,000.00 บาท ต่อครั้ง
          </p>
        </div>
      </div>
    );
  }
}
