import React, { Component } from 'react';
import { Dialog } from '@material-ui/core';
import '../MTLTaxConsent.css';
import { HttpClient } from '../../../services/HttpClient';
import moment from 'moment';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
export default class Request extends Component<any, any> {
  async componentDidMount() {
    try {
      const httpClient = new HttpClient();
      const response = await httpClient.post(
        '/tax-consent/policy-tax-deductible',
        { year: this.state.year },
      );

      const policytaxDeductibleList = Array.isArray(
        response['tns:fld_policy_tax_deductible_list'],
      )
        ? response['tns:fld_policy_tax_deductible_list']
        : Array(response['tns:fld_policy_tax_deductible_list']);
      const consentSelect = policytaxDeductibleList.filter(
        (item: any) => item['tns:tax_consent_last_status'] === 'Y',
      );
      if (response['tns:fld_result'] === 'found') {
        this.setState({
          ...this.state,
          taxDeductibleList: policytaxDeductibleList,
          consentSelect: consentSelect,
          statusDeductibleListOld: policytaxDeductibleList.map((item: any) => {
            return {
              policyNum: item['tns:policy_number'],
              status: item['tns:tax_consent_last_status'],
            };
          }),
          name: response.name,
          surname: response.surname,
          selectAll: true,
          submit: consentSelect.length > 0,
        });
      }
      if (!response) {
        this.props.history.push(`/taxConsent/rule`);
      }
      await httpClient.post(`/customers/tracking`, {
        uid: sessionStorage.getItem('uid'),
        trackingKey: 'tax-consent-view',
        info: { taxConsent: 'request' },
      });
    } catch (err) {
      console.log(err);
      window.location.href = `https://liff.line.me/${process.env.REACT_APP_LIFF_MTL_CONNECT}`;
    }
  }

  constructor(prop: any) {
    super(prop);
    this.state = {
      allowConsent: 'accept',
      consentSelect: [],
      selectAll: false,
      isModalOpen: false,
      taxDeductibleList: [],
      statusDeductibleListOld: [],
      year: new Date().getFullYear() + 543,
      sending: false,
      name: 'Name',
      surname: 'Surname',
      submit: false,
      errorState: false,
    };
  }

  onRadioChange = (event: any) => {
    let result;
    let check = this.state.consentSelect.filter(
      (item: any) => item['tns:tax_consent_last_status'] === 'Y',
    );
    if (event.target.value === 'decline') {
      result = this.updateConsentAll('N');
      this.setState({ ...this.state, consentSelect: result });
      this.setState({ submit: true, selectAll: false });
    } else {
      this.setState({ submit: check.length > 0, selectAll: true });
    }
    this.setState({ allowConsent: event.target.value });
  };

  onSelectChange(id: any) {
    const taxList = this.state.taxDeductibleList;
    const find = taxList.find((item: any) => item['tns:policy_number'] === id);
    if (find) {
      const result = this.updateConsentByOne(find['tns:policy_number']);
      this.setState({ ...this.state, consentSelect: result });
      let check = result.filter(
        (item: any) => item['tns:tax_consent_last_status'] === 'Y',
      );
      this.setState({ submit: check.length > 0 });
    }
  }

  selectAll = () => {
    const result = this.updateConsentAll('Y');
    this.setState({ consentSelect: result });
  };

  checkmark = (id: any) => {
    const find = this.state.consentSelect.find(
      (item: any) => item['tns:policy_number'] === id,
    );
    if (find) {
      return find['tns:tax_consent_last_status'] === 'Y';
    }
  };

  handleOpen = async () => {
    const httpClient = new HttpClient();
    await httpClient.post(`/customers/tracking`, {
      uid: sessionStorage.getItem('uid'),
      trackingKey: 'tax-consent-confirm',
      info: { taxConsent: 'request' },
    });
    this.setState({ isModalOpen: true });
  };

  handleClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleSubmit = async () => {
    try {
      this.setState({ sending: true });
      const httpClient = new HttpClient();
      let consentSelect = this.state.consentSelect;
      let updateData = consentSelect.map((data: any) => ({
        policyNumber: data['tns:policy_number'],
        taxConcentStatus: data['tns:tax_consent_last_status'],
      }));
      const getTaxConsentData: any[] = [];
      if (this.state.allowConsent === 'accept') {
        consentSelect.forEach((data: any) => {
          if (data['tns:tax_consent_last_status'] === 'Y') {
            getTaxConsentData.push({
              PolicyNumber: data['tns:policy_number'],
              PlanName: data['tns:plan_name_thai'],
            });
          }
        });
      }

      const response = await httpClient.post('/tax-consent/set-tax-consent', {
        policyList: updateData,
        genTaxConsent: {
          isConsent: getTaxConsentData.length > 0 ? 'true' : 'false',
          lang: 'th',
          policyDetail: getTaxConsentData,
        },
      });
      if (response) {
        const httpClient = new HttpClient();
        await httpClient.post(`/customers/tracking`, {
          uid: sessionStorage.getItem('uid'),
          trackingKey: 'tax-consent-confirm',
          info: { taxConsent: 'request-dialog' },
        });
        this.handleClose();
        this.props.history.push('/taxConsent/result');
      }
    } catch (error) {
      console.log(error);
      //Waiting Error Page
      this.setState({ errorState: true });
    }
  };

  updateConsentByOne = (id: any) => {
    let selectedArr = this.state.consentSelect;
    const taxArr = this.state.taxDeductibleList;
    let tax = taxArr.find((item: any) => item['tns:policy_number'] === id);
    let selectedTax = selectedArr.find(
      (item: any) => item['tns:policy_number'] === id,
    );
    if (selectedTax) {
      for (var i in selectedArr) {
        if (selectedArr[i]['tns:policy_number'] === id) {
          this.state.consentSelect[i]['tns:tax_consent_last_status'] =
            this.state.consentSelect[i]['tns:tax_consent_last_status'] === 'Y'
              ? 'N'
              : 'Y';
          break;
        }
      }
    }
    if (!selectedTax) {
      tax['tns:tax_consent_last_status'] =
        tax['tns:tax_consent_last_status'] === 'Y' ? 'N' : 'Y';
      selectedArr.push(tax);
    }
    return selectedArr;
  };

  updateConsentAll = (status: string) => {
    let arr = this.state.taxDeductibleList;
    for (var i in arr) {
      this.state.taxDeductibleList[i]['tns:tax_consent_last_status'] = status;
    }
    this.setState({ submit: true });
    return arr;
  };

  render() {
    return (
      <div className="wrap-all">
        <div className="wrap-logo">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="logo-smile">
            <img src="/images/Graphic.svg" alt="smileLogo" />
          </div>
        </div>
        <div className="wrap-content" style={{ paddingBottom: '60px' }}>
          <h2 className="header">
            แจ้งความประสงค์ในการใช้สิทธิขอยกเว้นภาษีเงินได้
            <br />
            และยินยอมให้บริษัทฯ เปิดเผยข้อมูลเกี่ยวกับ
            <br />
            เบี้ยประกันภัยต่อกรมสรรพากร
            <br />
          </h2>
          <div className="box-consent">
            <span className="text-consent">
              ปีภาษีที่ต้องการแจ้งความประสงค์
            </span>
            <div className="border-box">{this.state.year}</div>
          </div>
          <form className="radio-consent">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="accept"
                  checked={this.state.allowConsent === 'accept'}
                  onChange={this.onRadioChange}
                  style={{ float: 'left' }}
                />
                <span className="content" style={{ fontSize: '16px' }}>
                  ข้าพเจ้ามีความประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฎหมาย
                  ว่าด้วยภาษีอากร และยินยอมให้ บมจ. เมืองไทยประกันชีวิต
                  นำส่งและเปิดเผยข้อมูลเบี้ยประกันภัย ต่อกรมสรรพากร ตาม
                  หลักเกณฑ์ วิธีการที่กรมสรรพากรกำหนด ของสัญญาประกันภัย เลขที่
                </span>
              </label>
            </div>
            <div
              className={
                this.state.selectAll ? 'consent-btn' : 'consent-btn-inactive'
              }
              onClick={() => this.selectAll()}
            >
              เลือกทั้งหมด
            </div>
            <div
              className="consent-line"
              style={{ marginTop: '5%', marginBottom: '8%' }}
            ></div>
            <div className="checkbox-warp">
              {this.state.taxDeductibleList.length > 0 &&
                this.state.taxDeductibleList.map((item: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="check-box">
                        <input
                          style={{ float: 'left' }}
                          onChange={() =>
                            this.onSelectChange(item['tns:policy_number'])
                          }
                          onSelect={() =>
                            this.state.taxDeductibleList.includes(
                              item['tns:policy_number'],
                            )
                          }
                          checked={this.checkmark(item['tns:policy_number'])}
                          id={`tex_consent` + item['tns:policy_number']}
                          name={`tex_consent` + item['tns:policy_number']}
                          type="checkbox"
                          disabled={this.state.allowConsent !== 'accept'}
                        />
                        <label
                          htmlFor={`tex_consent` + item['tns:policy_number']}
                          className="check"
                        ></label>
                      </div>
                      <div className="warp-text">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>กรมธรรม์ประกันภัยเลขที่</span>
                          <span>แบบประกันภัย</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span className="content-left">
                            {item['tns:policy_number']}
                          </span>
                          <span className="content-right">
                            {item['tns:plan_name_thai']}
                          </span>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '100%',
                          marginLeft: '26px',
                          marginBottom: '7%',
                        }}
                        className="warp-text"
                      >
                        <span>สถานะ</span>
                        {
                          <img
                            src={
                              this.state.statusDeductibleListOld.find(
                                (data: any) =>
                                  data.policyNum === item['tns:policy_number'],
                              )['status'] === 'Y'
                                ? '/images/check-circle.svg'
                                : '/images/x-circle.svg'
                            }
                            style={{ width: '5%', margin: '1% 3% -3px 3%' }}
                            alt="status"
                          />
                        }
                        <span>แจ้งความประสงค์ใช้สิทธิวันที่</span>
                        <span className="text" style={{ marginLeft: '3%' }}>
                          {!!item['tns:tax_consent_last_updated_date_time']
                            ? moment(
                                item[
                                  'tns:tax_consent_last_updated_date_time'
                                ].split(' ')[0],
                                'YYYYMMDD',
                              ).format('DD/MM/YYYY')
                            : '-'}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="consent-line" style={{ marginBottom: '7%' }}></div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="decline"
                  checked={this.state.allowConsent === 'decline'}
                  onChange={this.onRadioChange}
                  style={{ float: 'left' }}
                />
                <span className="content" style={{ fontSize: '16px' }}>
                  ข้าพเจ้าไม่มีความประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตาม
                  กฎหมายว่าด้วยภาษีอากร ทุกสัญญาประกันภัย
                </span>
              </label>
            </div>
            <fieldset>
              <div
                className={
                  this.state.submit
                    ? 'consent-submit'
                    : 'consent-submit-inactive'
                }
                onClick={this.handleOpen}
              >
                ส่งข้อมูล
              </div>
            </fieldset>
          </form>
          <div className="ps-content">
            หมายเหตุ
            <br />
            1. กรณีที่ท่านไม่ได้แจ้งความประสงค์ บริษัทฯ
            จะถือว่าท่านไม่ให้ความยินยอม
            ในการนำส่งและเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อ
            กรมสรรพากรท่านจะไม่สามารถนำเบี้ยประกันภัยดังกล่าวไปใช้สิทธิขอยกเว้นภาษีได้
            <br />
            2. กรณีที่ท่านเคยแจ้งความประสงค์ไว้แล้ว บริษัทฯ จะถือว่าการแจ้งความ
            ประสงค์ในครั้งนี้ เป็นการแจ้งความประสงค์ล่าสุดของท่าน
            <br />
            3. สัญญาประกันภัยข้างต้น เป็นสัญญาประกันภัยที่สามารถนำ
            เบี้ยประกันภัยไปใช้สิทธิยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร
            แต่ไม่รวมถึงสัญญาประกันภัยที่อยู่ระหว่างการพิจารณารับประกัน
          </div>
        </div>
        <Dialog
          open={this.state.isModalOpen}
          onClose={this.handleClose}
          aria-labelledby="simple-dialog-title"
        >
          {this.state.errorState ? (
            <div className="wrap-modal" style={{ padding: '20px' }}>
              <div
                className="error-icon"
                style={{ width: '100%', textAlign: 'center' }}
              >
                <img
                  src="/images/error-icon.png"
                  alt="erroricon"
                  style={{ width: '30%' }}
                />
              </div>
              <div className="alert-text" style={{ textAlign: 'center' }}>
                <h2>ขออภัยค่ะ</h2>
                <p>
                  ไม่สามารถทำรายการได้ในขณะนี้
                  <br />
                  กรุณาลองใหม่อีกครั้ง
                </p>
              </div>
              <div className="modal-error">
                <div onClick={this.handleClose} style={{ margin: 'auto' }}>
                  ปิด
                </div>
              </div>
            </div>
          ) : (
            <div style={{ width: '330px', padding: '10px' }}>
              <div className="wrap-modal" style={{ marginTop: '10%' }}>
                <h2 className="header">
                  แบบฟอร์มแจ้งความประสงค์ในการใช้สิทธิ
                  <br />
                  ขอยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร
                  <br />
                </h2>
                {this.state.allowConsent === 'accept' ? (
                  <div className="modal-text">
                    ข้าพเจ้า {`${this.state.name} ${this.state.surname}`}{' '}
                    มีความประสงค์ใช้สิทธิขอยกเว้นภาษีเงินได้
                    ตามกฎหมายว่าด้วยภาษีอากร และยินยอมให้ บมจ.
                    เมืองไทยประกันชีวิต
                    นำส่งและเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร
                    ตามหลักเกณฑ์
                    วิธีการที่กรมสรรพากรกำหนดของสัญญาประกันภัยเลขที่
                  </div>
                ) : (
                  <div className="modal-text">
                    ข้าพเจ้าไม่มีความประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตาม
                    กฎหมายว่าด้วยภาษีอากร ทุกสัญญาประกันภัย
                  </div>
                )}
                <div className="modal-highlight">
                  {this.state.consentSelect.length > 0 &&
                    this.state.consentSelect.map((item: any, index: number) => {
                      if (item['tns:tax_consent_last_status'] === 'Y')
                        return (
                          <p style={{ marginBottom: '2%' }} key={index}>
                            {item['tns:policy_number']} (
                            {item['tns:plan_name_thai']})
                          </p>
                        );
                    })}
                </div>
                <div className="modal-ps">
                  <span style={{ color: '#C00D0D' }}>หมายเหตุ</span> <br />
                  1. กรณีที่ท่านไม่ได้แจ้งความประสงค์ บริษัทฯ
                  จะถือว่าท่านไม่ให้ความยินยอมในการ
                  นำส่งและเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร
                  ท่านจะไม่สามารถ
                  นำเบี้ยประกันภัยดังกล่าวไปใช้สิทธิขอยกเว้นภาษีได้ <br />
                  2. กรณีที่ท่านเคยแจ้งความประสงค์ไว้แล้ว บริษัทฯ
                  จะถือว่าการแจ้งความประสงค์ใน ครั้งนี้
                  เป็นการแจ้งความประสงค์ล่าสุดของท่าน <br />
                  3. สัญญาประกันภัยข้างต้น
                  เป็นสัญญาประกันภัยที่สามารถนำเบี้ยประกันภัยไปใช้
                  สิทธิยกเว้นภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร
                  แต่ไม่รวมถึงสัญญาประกันภัย ที่อยู่ระหว่างการพิจารณารับประกัน
                </div>
                <div className="modal-btn">
                  <div
                    style={{
                      float: 'left',
                      marginLeft: '19%',
                      cursor: 'pointer',
                    }}
                    onClick={this.handleClose}
                  >
                    แก้ไข
                  </div>
                  <div
                    className={
                      this.state.sending
                        ? 'modal-submit-inactive'
                        : 'modal-submit'
                    }
                    onClick={this.handleSubmit}
                  >
                    {this.state.sending ? (
                      <span style={{ margin: 'auto' }}>กรุณารอสักครู่</span>
                    ) : (
                      <span style={{ margin: 'auto' }}>ยืนยัน</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    );
  }
}
