import React from 'react';
import QRCode from 'qrcode.react';
import './payment/style.css';
import './payment/responsive.css';
import { HttpClient } from '../../services/HttpClient';

const liff = window.liff;
class MTLPayment extends React.Component<any, any> {
  Barcode = require('react-barcode');

  constructor(props: any) {
    super(props);
    this.state = {
      qrvalue: '',
      fullBarcode: false,
      name: '',
      policyNumber: '',
      screen: 'portrait',
      type: null,
      amount: 0,
      billerID: null,
      amountText: '',
      paidmode: '',
    };
  }

  zoomBarcode = () => {
    this.setState({ fullBarcode: true });
  };

  closeBarcode = () => {
    this.setState({ fullBarcode: false });
  };

  setScreenOrientation = async () => {
    const width = window.innerWidth || document.body.clientWidth;
    const height = window.innerHeight || document.body.clientHeight;
    if (width > height) {
      await this.setState({ screen: 'landscape' });
    } else {
      await this.setState({ screen: 'portrait' });
    }
  };

  async componentDidMount() {
    await liff.init({ liffId: process.env.REACT_APP_LIFF_PAYMENT }); // staging
    if (!liff.isLoggedIn()) {
      liff.login({
        redirectUri: window.location.href,
      });
      return;
    }
    const httpClient = new HttpClient();
    const profile = await liff.getProfile();
    document.title = 'MTL Payment';
    this.setScreenOrientation();
    window.addEventListener('resize', this.setScreenOrientation);
    let dataObject: any = {};
    let search = window.location.search;
    search = decodeURIComponent(search);
    search = search.replace(/\%/g, '%25');
    search = decodeURIComponent(search);
    search = search.replace('liff.state=', '');
    search = search.replace(/\?/g, '');
    search = search.replace(/\+/g, ' ');

    const dataArray = search.split('&');
    dataArray.forEach((data) => {
      const field = data.split('=');
      dataObject[field[0]] = field[1];
    });
    // const qrvalueTest = `|010755500040607\n160356005692097945\n0819097810\n3750000`;
    let amount = dataObject.amount;
    const amountText = dataObject.amountText;
    amount = amount.replace(',', '');
    amount = amount.replace('.', '');
    console.log(dataObject);
    await httpClient.post(`/customers/tracking`, {
      uid: profile.userId,
      trackingKey:
        dataObject.type === 'qrcode'
          ? 'payment-mobile-banking'
          : 'payment-counter',
      info: { payment: dataObject.type },
    });
    let qrvalue = `|${dataObject.taxid}${dataObject.bank}\r${dataObject.policyNumber}\r\r${amount}`;
    if (dataObject.type === 'qrcode')
      qrvalue = `|${dataObject.taxid}${dataObject.bank}\r2${dataObject.policyNumber}000000\r0\r${amount}`;
    this.setState({
      qrvalue,
      name: dataObject.name,
      policyNumber: dataObject.policyNumber,
      type: dataObject.type,
      amount: dataObject.amountText,
      paid_mode: dataObject.paid_mode,
      billerID: `${dataObject.taxid}${dataObject.bank}`,
      amountText,
      paidmode: dataObject.paid_mode,
    });
    console.log(this.state);
  }

  gotoQRDetail = () => {
    this.props.history.push('/qrpayment');
  };

  gotoBarcodeDetail = () => {
    this.props.history.push('/barcodepayment');
  };

  render() {
    if (this.state.type === 'qrcode') {
      return (
        <React.Fragment>
          <div className="wrap-all">
            <div className="wrap-content wrap-barcode">
              <div className="barcode-head">
                <div className="logo-mtl">
                  <img src="images/MTL-logo.png" alt="logo" />
                </div>
                <div
                  className="barcode-btn"
                  onClick={() => this.gotoQRDetail()}
                >
                  ช่องทางการชำระเงิน
                </div>
              </div>

              <h3>ชำระเบี้ยประกันภัย</h3>
              <div className="barcode-content-wrap qr-content">
                <div className="barcode-content-left">ข้อมูลกรมธรรม์</div>
                <div className="barcode-content-right">
                  {this.state.policyNumber}
                </div>
              </div>
              <div className="barcode-content-wrap qr-content">
                <div className="barcode-content-left">แผนประกันภัย</div>
                <div className="barcode-content-right">{this.state.name}</div>
              </div>
              <div className="barcode-content-wrap qr-content">
                <div className="barcode-content-left">เบี้ยประกันภัย</div>
                <div className="barcode-content-right">
                  {this.state.amountText} บาท/{this.state.paidmode}
                </div>
              </div>
              <br />
              <h3>สำหรับชำระเงินข้ามธนาคารช่องทางอิเล็กทรอนิกส์</h3>
              <div className="qr-img">
                <QRCode value={this.state.qrvalue} size={200} />
              </div>
              <h4>Biller ID : {this.state.billerID}</h4>
            </div>
          </div>
        </React.Fragment>
      );
    } else if (this.state.type === 'barcode') {
      return (
        <React.Fragment>
          <div className="wrap-all">
            <div className={'wrap-content wrap-barcode ' + this.state.screen}>
              <div className="barcode-head">
                <div className="logo-mtl">
                  <img src="images/MTL-logo.png" alt="logo" />
                </div>
                <div
                  className="barcode-btn"
                  onClick={() => this.gotoBarcodeDetail()}
                >
                  ช่องทางการชำระเงิน
                </div>
              </div>

              <h3>ชำระเบี้ยประกันภัย</h3>
              <div className="barcode-content-wrap">
                <div className="barcode-content-left">ข้อมูลกรมธรรม์</div>
                <div className="barcode-content-right">
                  {this.state.policyNumber}
                </div>
              </div>
              <div className="barcode-content-wrap">
                <div className="barcode-content-left">แผนประกันภัย</div>
                <div className="barcode-content-right">{this.state.name}</div>
              </div>
              <div className="barcode-content-wrap">
                <div className="barcode-content-left">เบี้ยประกันภัย</div>
                <div className="barcode-content-right">
                  {this.state.amountText} บาท/{this.state.paidmode}
                </div>
              </div>

              <div className="barcode-img">
                <this.Barcode
                  value={this.state.qrvalue}
                  width={2}
                  renderAs="svg"
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <p></p>;
    }
  }
}

export default MTLPayment;
