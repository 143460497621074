import React, { Component } from "react";
import InputMask from "react-input-mask";
import { HttpClient } from "../../../services/HttpClient";

class OTPForm extends Component<any, any> {
  httpClient = new HttpClient();

  constructor(props: any) {
    super(props);
    this.state = {
      OTP: "",
      counting: false,
      timeout: 30,
      submitEnable: false,
      loaded: true,
      clientNumber: "",
      data: {},
      showErrorModal: false
    };
  }

  async componentDidMount() {
    const agreeStr = window.sessionStorage.getItem("consent_agree");
    if (!agreeStr) {
      this.props.history.push(`/smileclub/consent`);
    } 
    
    const data = this.props.location.state;
    if (data && (data.hasOwnProperty("email") || data.hasOwnProperty("sms"))) {
      let clientNumber;
      if (sessionStorage.getItem("clientNumber")) {
        clientNumber = sessionStorage.getItem("clientNumber");
      }
      await this.setState({ data, clientNumber });
      setTimeout(() => {
        this.sentOTP();
      }, 1000);
    } else {
      this.props.history.push("/smileclub/register-form");
    }
  }

  inputChange = async (e: any) => {
    const { name, value } = e.target;
    const state: any = {};
    state[name] = value;
    await this.setState(state);
    await this.validateInput();
  };

  validateInput = async () => {
    let submitEnable = false;
    let { OTP } = this.state;
    OTP = OTP.replace(/_/g, "");
    submitEnable = OTP.length === 5;
    await this.setState({ submitEnable });
  };

  sentOTP = async () => {
    const vm = this;
    const data = this.state.data;
    const key = Object.keys(data)[0];
    const value = data[key];
    if (!vm.state.counting) {
      this.setState({loaded: false});
      const response = await this.httpClient.post("/register/sentOTP", {
        clientNumber: vm.state.clientNumber,
        sendTo: value,
        typeOfSending: key
      });
      this.setState({loaded: true});
      console.log("sent otp", response);
      let timeout = this.state.timeout;
      vm.setState({ counting: true });
      const timer = setInterval(() => {
        if (timeout === 1) {
          vm.setState({ counting: false, timeout: 30 });
          clearInterval(timer);
        } else {
          timeout--;
          vm.setState({ timeout });
        }
      }, 1000);
    }
  };

  submitHandler = async () => {
    if (this.state.submitEnable) {
      try {
        this.setState({loaded: false});
        const response = await this.httpClient.post("/register/verifyOTP", {
          OTP: this.state.OTP,
          clientNumber: this.state.clientNumber
        });
        this.setState({loaded: true});
        if (response && response.status === "valid") {
          this.props.history.push("/smileclub/member");
        } else {
          this.setState({ showErrorModal: true });
        }
      } catch (err) {
        console.log(err);
        this.setState({loaded: true});
      }
      
    }
  };

  handleTryAgain = (e: any) => {
    const target = e.target;
    if (target.id !== "try-again") {
      return;
    }
    this.closeModal();
  };

  closeModal = () => {
    this.setState({ showErrorModal: false });
  };

  render() {
    return (
      <React.Fragment>
        <div id="loader" style={{display: (this.state.loaded) ? 'none': 'block'}}></div>
        <div className="wrap-all otp-wrap" style={{display: (this.state.loaded) ? 'block': 'none'}}>
          <div className="wrap-logo">
            <div className="logo-mtl">
              <img src="/images/MTL-logo.png" alt="mtlLogo" />
            </div>
            <div className="logo-smile">
              <img src="/images/smileClub-logo.png" alt="smileClubLogo" />
            </div>
          </div>
          <div className="wrap-content" style={{paddingBottom: '60px'}}>
            <div className="smile-club-banner">
              <img src="/images/smile-club.png" alt="smileclub" />
            </div>
            <h2>
              ลงทะเบียนสมาชิก<br /> <b>เมืองไทยสไมล์คลับ</b>
            </h2>
            <div className="register-smile">
              <p>กรอกรหัส OTP เพื่อยืนยันตัวตน</p>
              <div className="section_input otp-input">
                <InputMask
                  mask="99999"
                  placeholder="กรุณากรอกรหัส OTP"
                  name="OTP"
                  id="OTP"
                  onChange={this.inputChange}
                  value={this.state.OTP}
                  pattern="\d*"
                  className={
                    this.state.OTP.length ? "input-center otp" : "input-center"
                  }
                />
              </div>
              <p
                className={
                  this.state.counting ? "otp-text disabled" : "otp-text"
                }
                onClick={this.sentOTP}
              >
                ส่ง OTP อีกครั้ง{" "}
                {this.state.counting ? (
                  <span>({this.state.timeout})</span>
                ) : null}
              </p>
            </div>
          </div>
          <div
            className={
              this.state.submitEnable ? "btn-signin-fixed" : "btn-signin-fixed btn-disabled"
            }
            onClick={this.submitHandler}
          >
            ลงชื่อเข้าใช้งาน
          </div>

          <div
            className="try-again"
            id="try-again"
            onClick={this.handleTryAgain}
            style={{ display: this.state.showErrorModal ? "" : "none" }}
          >
            <div className="try-again-wrap">
              <div className="try-again-box">
                <div className="close-btn">
                  <img
                    src="/images/close-icon.png"
                    alt="closeIcon"
                    onClick={this.closeModal}
                  />
                </div>
                <div className="try-again-text">
                  <h4>ขออภัยค่ะ</h4>
                  <p>
                    คุณกรอกรหัส OTP ไม่ถูกต้อง
                    <br />
                    กรุณาลองใหม่อีกครั้ง
                  </p>
                </div>
                <div className="try-again-btn" onClick={this.closeModal}>
                  ลองอีกครั้ง
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OTPForm;
