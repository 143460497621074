import React, { Component } from 'react';
import axios from 'axios';
import { HttpClient } from '../../../services/HttpClient';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

export default class TaxCertificate extends Component<any, any> {
  state = {
    roundHash: 0,
    disable: false,
  };
  async getHashTaxCert(taxCert: string) {
    this.setState({ disable: true });
    try {
      const httpClient = new HttpClient();
      const hashTaxCert = await httpClient.get(
        `/customers/tax-cert/policy/${taxCert}`,
      );
      this.setState({ disable: false, roundHash: 0 });
      return hashTaxCert.hash;
    } catch (error) {
      console.log(error);
      if (this.state.roundHash <= 10) {
        const lineAccessToken = await liff.getAccessToken();
        const profile = await liff.getProfile();
        const response = await axios.post(
          `${API_URL}/register/getAccessToken`,
          {
            token: lineAccessToken,
          },
        );
        const refresh_token = response.data.data;
        sessionStorage.setItem('refresh_token', refresh_token);
        sessionStorage.setItem('uid', profile.userId);
        this.setState({ roundHash: this.state.roundHash + 1 });
        await this.getHashTaxCert(taxCert);
      } else {
        alert('กรุณาลองใหม่อีกครั้ง');
        this.setState({ disable: false, roundHash: 0 });
      }
    }
  }
  render() {
    return (
      <div className="content">
        <ul>
          {this.props.policyList.map((item: any) => {
            return (
              <li key={`${item.fld_policy_no}`}>
                <div className="content-box">
                  <div className="content-main">
                    {item['tns:policy_number']}
                    <p className="content-main-sub">
                      {item['tns:plan_name_thai']}
                    </p>
                  </div>
                  <div className="btn-box">
                    <button
                      type="button"
                      className="btn"
                      disabled={this.state.disable}
                      onClick={async () => {
                        const hash = await this.getHashTaxCert(
                          item['tns:policy_number'],
                        );
                        if (hash) {
                          if (liff.isInClient()) {
                            liff.openWindow({
                              url: `${window.location.origin}/api/taxCert/download/${hash}?openExternalBrowser=1`,
                              external: true,
                            });
                          } else {
                            window.open(
                              `${window.location.origin}/api/taxCert/download/${hash}?openExternalBrowser=1`,
                            );
                          }
                          const httpClient = new HttpClient();
                          await httpClient.post(`/customers/tracking`, {
                            uid: sessionStorage.getItem('uid'),
                            trackingKey: 'tax-cert-download',
                          });
                        }
                      }}
                    >
                      ดาวน์โหลด
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
