import React from 'react';
import '../payment/style.css';
import '../payment/responsive.css';

export default class MTLPaymentBarcode extends React.Component<any, any> {
  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div className="wrap-all">
        <div className="wrap-content wrap-barcode payment-wrap">
          <div
            className="barcode-btn payment-back-btn"
            onClick={() => this.goBack()}
          >
            กลับหน้าชำระเงิน
          </div>
          <h4>
            <span style={{ color: '#E6007E' }}>ช่องทางการชำระเงิน</span>
            <br />
            ผ่านเคาน์เตอร์ธนาคารและจุดบริการชำระเงิน <br />
            (Bill Payment)
          </h4>
          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/Kasikorn.png" alt="Kasikorn" />
              </div>
              <div className="pay-info">
                <p>ธ.กสิกรไทย</p>
                <p>COMP CODE : 33559</p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/SCB.png" alt="SCB" />
              </div>
              <div className="pay-info">
                <p>ธ.ไทยพาณิชย์</p>
                <p>COMP CODE : 1766 ช่องทางดิจิตอลแบงกิ้ง</p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/Bangkok-bank.png" alt="Bangkok" />
              </div>
              <div className="pay-info">
                <p>ธ.กรุงเทพ</p>
                <p>(Br.No.101) Service Code : MTHAI</p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/KTB.png" alt="KTB" />
              </div>
              <div className="pay-info">
                <p>ธ.กรุงไทย</p>
                <p>COMP CODE : MDS250</p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/Krungsri.png" alt="Krungsri" />
              </div>
              <div className="pay-info">
                <p>ธ.กรุงศรีอยุธยา</p>
                <p>COMP CODE : 46364</p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/qr-TTB2.png" alt="TTB" />
              </div>
              <div className="pay-info">
                <p>ธ.ทหารไทยธนชาต</p>
                <p>COMP CODE : 161</p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/UOB.png" alt="UOB" />
              </div>
              <div className="pay-info">
                <p>ธ.ยูโอบี</p>
                <p>BP Code : 1720</p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/CIMB.png" alt="CIMB" />
              </div>
              <div className="pay-info">
                <p>ธ.ซีไอเอ็มบีไทย</p>
                <p>TRX Code : 3402/3412</p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/GSB.png" alt="GSB" />
              </div>
              <div className="pay-info">
                <p>ธ.ออมสิน</p>
                <p>PAY Code : 3101-0396-720</p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/BAAC.png" alt="BAAC" />
              </div>
              <div className="pay-info">
                <p>ธ.เพื่อการเกษตรและสหกรณ์</p>
                <p>Code : MI01</p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/ibank.png" alt="ibank" />
              </div>
              <div className="pay-info">
                <p>ธ.อิสลามแห่งประเทศไทย</p>
                <p>COMP CODE : 0106</p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/LHBank.png" alt="LHBank" />
              </div>
              <div className="pay-info">
                <p>ธ.แลนด์ แอนด์ เฮ้าส์</p>
                <p>Service Code : MTL</p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/lotus.jpg" alt="lotus" />
              </div>
              <div className="pay-info">
                <p>
                  เทสโก้ โลตัส{' '}
                  <span className="remark-text">(ไม่เกิน 49,000 บาท)</span>
                </p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/Mpay.png" alt="Mpay" />
              </div>
              <div className="pay-info">
                <p>จุดรับชำระเงินเอ็มเปย์ สเตชั่น</p>
                <p>
                  <span className="remark-text">(ไม่เกิน 49,000 บาท)</span>
                </p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/true-money.png" alt="true-money" />
              </div>
              <div className="pay-info">
                <p>ทรูมันนี่</p>
                <p>
                  <span className="remark-text">
                    (ไม่เกิน 30,000 บาท) Ture Money Wallet แอพพิเคชั่นบนมือถือ
                    ไม่เกิน 90,000 บาท/ราย
                  </span>
                </p>
              </div>
            </div>
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/thaipost.png" alt="thaipost" />
              </div>
              <div className="pay-info">
                <p>ที่ทำการไปรษณีย์ทั่วประเทศ</p>
                <p>รหัสบริการ 577</p>
                <p>
                  <span className="remark-text">(ไม่เกิน 49,000 บาท)</span>
                </p>
              </div>
            </div>
          </div>

          <div className="payment-content-wrap">
            <div className="payment-content">
              <div className="pay-logo">
                <img src="images/7-11.jpeg" alt="7-11" />
              </div>
              <div className="pay-info">
                <p>
                  เคาน์เตอร์เซอร์วิส{' '}
                  <span className="remark-text">(ไม่เกิน 49,000 บาท)</span>
                </p>
              </div>
            </div>
            <div className="payment-content"></div>
          </div>
          <p className="pay-note">
            หมายเหตุ : <br />
            -
            การชำระเงินผ่านจุดบริการจะชำระเงินได้ไม่เกินวงเงินที่จุดบริการกำหนด
            และมีค่าบริการ ต่อครั้ง <br />-
            การชำระเงินผ่านเคาน์เตอร์ธนาคารไม่จำกัดวงเงิน
            และไม่มีค่าบริการต่อครั้ง
          </p>
        </div>
      </div>
    );
  }
}
