import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Consent from './pages/Consent';
import NewConsent from './pages/NewConsent';
import Register from './pages/Validator';
import Result from './pages/Result';
import RequestOTP from './pages/RequestOTP';
import VerifyOTP from './pages/VerifyOTP';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

export class MTLConnect extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasToken: false,
    };
  }

  async componentDidMount() {
    document.title = 'MTL Mini Click';
    // liff.init({ liffId: '1475387349-d3ZjMbBy' }) // production
    liff
      .init({ liffId: process.env.REACT_APP_LIFF_MTL_CONNECT }) // staging
      // liff.init({ liffId: "1538922777-kYBK2PnY" }) // test (local)
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const lineAccessToken = liff.getAccessToken();
          const response = await axios.post(
            `${API_URL}/register/getAccessToken`,
            { token: lineAccessToken },
          );
          const profile = await liff.getProfile();
          const refresh_token = response.data.data;
          sessionStorage.setItem('refresh_token', refresh_token);
          await axios.post(
            `${API_URL}/customers/tracking`,
            { uid: profile.userId, trackingKey: 'register' },
            {
              headers: { Authorization: `bearer ${refresh_token}` },
            },
          );

          await this.setState({ hasToken: true });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  render() {
    return (
      <Switch>
        <Route exact path="/mtl-connect/terms">
          <Consent loadedToken={this.state.hasToken} {...this.props} />
        </Route>
        <Route exact path="/mtl-connect/consent">
          <NewConsent loadedToken={this.state.hasToken} {...this.props} />
        </Route>
        <Route path={`/mtl-connect/validator`}>
          <Register loadedToken={this.state.hasToken} {...this.props} />
        </Route>
        <Route path={`/mtl-connect/result`}>
          <Result loadedToken={this.state.hasToken} {...this.props} />
        </Route>
        <Route path={`/mtl-connect/requestOTP`}>
          <RequestOTP loadedToken={this.state.hasToken} {...this.props} />
        </Route>
        <Route path={`/mtl-connect/verifyOTP`}>
          <VerifyOTP loadedToken={this.state.hasToken} {...this.props} />
        </Route>
        <Redirect exact from="/mtl-connect" to="/mtl-connect/terms" />
      </Switch>
    );
  }
}
