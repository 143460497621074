import axios from 'axios';
const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

export class HttpClient {
  // axiosInstance = axios;
  constructor() {
    axios.interceptors.request.use(async (config) => {
      const authorization = {
        Authorization:
          'bearer ' + window.sessionStorage.getItem('refresh_token'),
      };
      config.headers = { ...authorization };
      return config;
    });
  }

  async handleError(err: any) {
    console.log(err);
    if (err.toString().indexOf('code 401') >= 0) {
      console.log('loggin again');
      const lineAccessToken = liff.getAccessToken();
      const response = await axios.post(`${API_URL}/register/getAccessToken`, {
        token: lineAccessToken,
      });
      const refresh_token = response.data.data;
      sessionStorage.setItem('refresh_token', refresh_token);
      window.location.reload();
    } else {
      if (window.location.pathname === '/taxConsent/request') {
        throw err;
      }
    }
  }

  async get(url: string, params: any = null) {
    return axios
      .get(API_URL + url, { params })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  async post(url: string, data: any = {}) {
    try {
      const response = await axios.post(API_URL + url, { ...data }, {});
      return response.data.data;
    } catch (err) {
      await this.handleError(err);
    }

    // return axios
    //   .post(API_URL + url, { ...data }, {})
    //   .then((res) => {
    //     return res.data.data;
    //   })
    //   .catch((err) => {
    //     this.handleError(err);
    //   });
  }
}
