import React, { Component } from "react";
import InputMask from "react-input-mask";
import { HttpClient } from "../../../services/HttpClient";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class Validator extends Component<any, any> {
  dateField: any;
  constructor(props: any) {
    super(props);
    this.state = {
      isShowCard: false,
      idcard: "",
      dob: "",
      isAndroid: false,
      idcard_isValid: true,
      dob_isValid: true,
      isFormValid: false,
      loaded: false,
      submitEnable: true,
      consent: null,
      openDialog: false,
    };
  }
  
  validateInput = async () => {
    let { idcard, dob, idcard_isValid, dob_isValid } = this.state;

    idcard = idcard.replace(/_/g, "");
    idcard = idcard.replace(/-/g, "");
    if (idcard.length > 0) {
      idcard_isValid = this.validateThaiCitizenID(idcard);
      this.setState({ idcard_isValid });
    }

    dob = dob.replace(/_/g, "");
    dob = dob.replace(/\//g, "");
    if (dob.length > 0) {
      dob_isValid = dob.length === 8 && (dob[5] === '4' || dob[5] === '5') && dob[4] === '2';
    }
    this.setState({ dob_isValid });
    const isFormValid =
      dob_isValid &&
      idcard_isValid &&
      idcard.length === 13 &&
      dob.length === 8;

    await this.setState({
      isFormValid,
      submitEnable: isFormValid
    });
  };

  inputChange = async (e: any) => {
    const { name, value } = e.target;
    const state: any = {};
    state[name] = value;
    await this.setState(state);
    await this.validateInput();
  };

  pushBottom = () => {
    if (this.state.isAndroid) {
      const memberContentPanel = document.getElementById("member-content");
      if (memberContentPanel) {
        memberContentPanel.style.height = "100vh";
        window.scrollTo(0, document.body.scrollHeight);
      }
    }
  };

  pullBottom = () => {
    if (this.state.isAndroid) {
      const memberContentPanel = document.getElementById("member-content");
      if (memberContentPanel) {
        memberContentPanel.style.height = "inherit";
        window.scrollTo(0, 0);
      }
    }
  };

  gotoTopPage = () => {
    //Go to top page
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "unknown";
  }

  validateThaiCitizenID = (id: string) => {
    if (id.length !== 13 || id.charAt(0).match(/[09]/)) return false;

    var sum = 0;
    for (let i = 0; i < 12; i++) {
      sum += parseInt(id.charAt(i)) * (13 - i);
    }

    if ((11 - (sum % 11)) % 10 !== parseInt(id.charAt(12))) {
      return false;
    }

    return true;
  };

  submitProcess = async () => {
    if (this.state.isFormValid && this.state.submitEnable) {
      await this.setState({ submitEnable: false, loaded: false });
      window.sessionStorage.setItem("idcard", this.state.idcard);
      window.sessionStorage.setItem("dob", this.state.dob);
      const liff = window.liff;
      const profile = await liff.getProfile();
      const httpClient = new HttpClient();
      try {
        const response = await httpClient.post("/register/validator", {
          idcard: this.state.idcard,
          dob: this.state.dob,
          uid: profile.userId
        });
        await this.setState({ submitEnable: true, loaded: true });
        const result = response.result;

        if (result === "success") {
          window.sessionStorage.setItem('clientId', response.clientId);
          this.props.history.push("/mtl-connect/requestOTP");
        } else {
          // this.props.history.push("/mtl-connect/result?page=validator&error=" + result);
          this.setState({ openDialog: true });
        }
      } catch (err) {
        // this.props.history.push("/mtl-connect/result?page=validator&error");
        this.setState({ openDialog: true });
      }
    }
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  }

  async componentDidMount() {
    const idcard = window.sessionStorage.getItem("idcard");
    const dob = window.sessionStorage.getItem("dob");
    const termsAgree = window.sessionStorage.getItem('termsAgree');

    if (!termsAgree) {
      this.props.history.push('/mtl-connect/terms');
    } else {
      if (termsAgree !== 'true') {
        this.props.history.push('/mtl-connect/terms');
      }
    }

    if (idcard && dob) {
      await this.setState({ dob, idcard });
    }
    this.validateInput();
    let search = this.props.location.search;
    search = search.replace("?", "");
    if (search === 'wrong_idcard') {
      await this.setState({ idcard_isValid: false });
    }
    if (search === 'wrong_dob') {
      await this.setState({ dob_isValid: false });
    }
  }

  render() {
    return (
      <React.Fragment>
        <link rel="stylesheet" type="text/css" href="/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/css/responsive.css"/>
        <link rel="stylesheet" type="text/css" href="/css/MTLConnect.css"/>
        <Dialog
            open={this.state.openDialog}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"ขออภัยค่ะ"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                หมายเลขบัตรประชาชน<br/>
                หรือ วัน เดือน ปี เกิด ของคุณไม่ถูกต้อง <br/>
                กรุณาตรวจสอบข้อมูล และลองใหม่อีกครั้ง<br/>
                สอบถามข้อมูลเพิ่มเติมโทร. 1766
               </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="default">
                ปิด
              </Button>
            </DialogActions>
          </Dialog>
        <div
          id="wrap-loader"
          style={{ display: this.state.loaded ? "none" : "" }}
        >
          <div
            id="loader"
            style={{ display: this.state.loaded ? "none" : "block" }}
          ></div>
        </div>
        <div className="wrap-term connect-wrap">
          <div className="wrap-logo center">
            <div className="logo-mtl">
              <img src="/images/MTL-logo.png" alt="logo" onLoad={() => this.setState({ loaded: true })} />
            </div>
          </div>
          <div className="member-content" id="member-content">
            <div className="wrap-content connect-content">
              <h3 style={{ textAlign: 'center' }}>ลงชื่อเข้าใช้งาน สำหรับลูกค้า MTL</h3>
              <div className="register-smile smile-member-info connect-input">
                <p style={{ color: '#555555', fontSize: '16px' }}>เลขที่บัตรประชาชน (ID Card Number)</p>
                <div className="section_input member-input">
                  <InputMask
                    mask="9-9999-99999-99-9"
                    placeholder="x-xxxx-xxxxx-xx-x"
                    type="tel"
                    name="idcard"
                    id="idcard"
                    onChange={this.inputChange}
                    value={this.state.idcard}
                    pattern="\d*"
                    className={
                      this.state.idcard_isValid ? "" : "input-invalid"
                    }
                  />
                </div>
              </div>
              <div className="register-smile smile-member-info connect-info">
                <div className="connect-text-wrap">
                  <p style={{ color: '#555555', fontSize: '16px' }}>วัน/เดือน/ปี เกิด พ.ศ. (Date of birth)</p>
                </div>

                <div className="section_input member-input">
                  <InputMask
                    mask="99/99/9999"
                    placeholder="dd/mm/yyyy"
                    type="tel"
                    name="dob"
                    id="dob"
                    onChange={this.inputChange}
                    value={this.state.dob}
                    pattern="\d*"
                    className={
                      this.state.dob_isValid ? "" : "input-invalid"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.submitEnable
                ? "btn-signin-fixed"
                : "btn-signin-fixed btn-disabled"
            }
            onClick={this.submitProcess}
          >
            ถัดไป
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Validator;
