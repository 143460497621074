import React, { Component } from "react";
import { HttpClient } from "../../../services/HttpClient";

class Consent extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      agree: false,
      consent: null,
      loaded: false,
      newConsent: false,
      menu: '',
    };
  }

  async componentDidMount() {
    let search = window.location.search;
    search = decodeURI(search);
    console.log(search);
    if (search.indexOf('menu') > -1) {
      document.title = 'Terms & Conditions';
      const menu = search.split('=')[1];
      await this.setState({ newConsent: true, menu });
    }
    const agree = window.sessionStorage.getItem('agree');
    if (agree) this.setState({ agree: true }); 
    if (this.props.loadedToken && !this.state.consent) {
      await this.loadConsent();
    }
  }

  async componentDidUpdate() {
    if (this.props.loadedToken && !this.state.consent) {
      await this.loadConsent();
    }
  }

  loadConsent = async () => {
    let consent = window.sessionStorage.getItem('consent');
    const httpClient = new HttpClient();
    if (consent) {
      consent = JSON.parse(consent);
    } else {
      consent = await httpClient.get(`/register/consent`);
    }
    await this.setState({ consent });
  };

  handleCheckbox = (event: any) => {
    this.setState({ agree: event.target.checked });
  };

  handleSubmit = (event: any) => {
    const { agree, newConsent } = this.state;
    if (agree) {
      if (newConsent) {
        return this.updateCustomerConsent();
      } else {
        window.sessionStorage.setItem('agree', agree);
        window.sessionStorage.setItem('consent', JSON.stringify(this.state.consent));
        this.props.history.push("/register-form");
      }
    }
  };

  updateCustomerConsent = async () => {
    this.setState({loaded: false});
    const httpClient = new HttpClient();
    const menu = this.state.menu;
    const liff = window.liff;
    const profile = await liff.getProfile();
    console.log(menu);
    await httpClient.post("/register/updateConsent", {
      consent: this.state.consent,
      uid: profile.userId
    });
    liff.sendMessages([
      {
        type: 'text',
        text: menu,
      }
    ])
    .then(() => {
      console.log('message sent');
    })
    .catch((err: any) => {
      console.log('error', err);
    });
    setTimeout(() => {
      liff.closeWindow();
    }, 1000);
  }

  render() {
    let btnClass = this.state.agree ? "btn-signin" : "btn-signin btn-disabled";
    if(this.state.consent) {
      return (
        <React.Fragment>
          <div id="loader" style={{display: (this.state.loaded) ? 'none': 'block'}}></div>
          <div className="wrap-term animate-fade" style={{display: (this.state.loaded) ? 'block': 'none'}}>
            <div className="wrap-logo">
              <div className="logo-mtl">
                <img src="/images/MTL-logo.png" alt="logo" />
              </div>
              <div className="logo-smile">
                <img src="/images/alert.png" alt="alert" onLoad={()=> this.setState({loaded: true})} />
              </div>
            </div>
            <div className="wrap-content term-content">
              <h2>Term & Conditions</h2>
              <p>{this.state.consent.consentLongText}</p>
              <div className="section_input condition-section">
                <div className="container">
                  <label htmlFor="checkbox">
                    ข้าพเจ้ายินยอมให้ บมจ. เมืองไทยประกันชีวิต
                    ทำการเก็บรวบรวมและใช้ข้อมูลที่ข้าพเจ้าให้ไว้
                    แก่นายหน้าประกันชีวิตและพันธมิตร
                  </label>
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="checkbox"
                    onChange={this.handleCheckbox}
                    checked={this.state.agree}
                  />
                  <label className="checkmark" htmlFor="checkbox"></label>
                </div>
              </div>
            </div>
            <div className={btnClass} onClick={this.handleSubmit}>
              ยินยอม
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return <div id="loader" style={{display: 'block'}}></div>;
    }
  }
}

export default Consent;
