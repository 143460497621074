import React, { Component } from "react";

class Result extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      status: "",
      errorCode: "",
      page: '',
    };
  }

  async componentDidMount() {
    const search = this.props.location.search;
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page');
    const error = urlParams.get('error');
    if (search.indexOf('error') > -1) {
      let errorCode = 'default';
      switch (error) {
        case 'idcard_not_found': errorCode = 'wrong_idcard'; break;
        case 'dob_not_match': errorCode = 'wrong_dob'; break;
        default: break;
      }
      this.setState({ status: "error", errorCode, page });
    } else {
      this.setState({ status: "success" });
      const liff = window.liff;
      if (liff.isInClient()) {
        setTimeout(() => {
          liff.closeWindow();
        }, 1500);
      }
    }
    this.gotoTopPage();
  }

  gotoTopPage = () => {
    //Go to top page
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  getDisplay = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');
    let message = 'ไม่สามารถทำรายการได้ในขณะนี้';
    switch (error) {
      case 'idcard_not_found': message = 'เลขบัตรประชาชนของท่านไม่ถูกต้อง'; break;
      case 'dob_not_match': message = 'วัน เดือน ปี เกิดไม่ถูกต้อง'; break;
      default: break;
    }
    if (this.state.status === "error") {
      return (
        <div className="wrap-content">
          <div className="error-icon">
            <img src="/images/error-icon.png" alt="erroricon" />
          </div>
          <div className="alert-text">
            <h2>ขออภัยค่ะ</h2>
            <p>
              {message}
              <br />
              กรุณาลองใหม่อีกครั้ง
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="wrap-content">
          <div className="error-icon">
            <img src="/images/success-icon.png" alt="success" />
          </div>
          <div className="alert-text success-text">
            <h2>ลงทะเบียนสำเร็จ</h2>
            <p>
              ระบบได้รับข้อมูลการลงทะเบียน
              <br />
              ของคุณเรียบร้อยเเล้ว
            </p>
          </div>
        </div>
      );
    }
  };

  goBack = () => {
    this.props.history.push(`/mtl-connect/${this.state.page}?${this.state.errorCode}`);
  }

  render() {
    return (
      <React.Fragment>
        <link rel="stylesheet" type="text/css" href="/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/css/responsive.css" />
        <link rel="stylesheet" type="text/css" href="/css/MTLConnect.css" />

        <div className="wrap-term connect-wrap">
          <div className="wrap-logo center">
            <div className="logo-mtl">
              <img src="/images/MTL-logo.png" alt="logo" />
            </div>
          </div>
          {this.getDisplay()}
          <div
            className="btn-signin-fixed"
            onClick={this.goBack}
            style={{ display: this.state.status === "error" ? "block" : "none" }}
          >
            ลองอีกครั้ง
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Result;
