import axios from 'axios';
import React, { Component } from 'react';
import { HttpClient } from '../../../services/HttpClient';
import '../MTLTaxConsent.css';

const liff = window.liff;
const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
export default class Result extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      getTaxConsentData: '',
    };
  }
  async componentDidMount() {
    const httpClient = new HttpClient();
    await httpClient.post(`/customers/tracking`, {
      uid: sessionStorage.getItem('uid'),
      trackingKey: 'tax-consent-confirm',
      info: { taxConsent: 'check' },
    });
  }

  handleSubmit = async () => {
    this.props.history.push('/taxConsent');
  };

  render() {
    const today = new Date().toLocaleDateString('th-TH', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return (
      <div className="wrap-all">
        <div className="wrap-logo">
          <div className="logo-mtl">
            <img src="/images/MTL-logo.png" alt="logo" />
          </div>
          <div className="logo-smile">
            <img src="/images/Graphic.svg" alt="smileLogo" />
          </div>
        </div>
        <div className="wrap-content" style={{ paddingBottom: '60px' }}>
          <div style={{ marginTop: '5%' }} className="error-icon">
            <img src="/images/check-circle.svg" alt="success" />
          </div>
          <div className="box-consent">
            ท่านได้ทำรายการเรียบร้อยแล้ว
            <br />
            เมื่อวันที่ {today}
            <br />
            บริษัทฯ จะดำเนินการนำส่งข้อมูลของท่านไปยัง
            <br />
            กรมสรรพากรตามรอบการส่งข้อมูลเพิ่มเติมของบริษัทฯ
            <br />
            โดยระยะเวลาการนำส่งขึ้นอยู่กับการพิจารณาของกรมสรรพากร
            <br />
            <div
              className="download"
              onClick={async () => {
                if (liff.isInClient()) {
                  liff.openWindow({
                    url: `${
                      window.location.origin
                    }/api/tax-consent/gen-tax-consent/${sessionStorage.getItem(
                      'clientNumber',
                    )}?openExternalBrowser=1`,
                    external: true,
                  });
                } else {
                  window.open(
                    `${
                      window.location.origin
                    }/api/tax-consent/gen-tax-consent/${sessionStorage.getItem(
                      'clientNumber',
                    )}?openExternalBrowser=1`,
                  );
                }

                const httpClient = new HttpClient();
                await httpClient.post(`/customers/tracking`, {
                  uid: sessionStorage.getItem('uid'),
                  trackingKey: 'tax-consent-download',
                  info: { taxConsent: 'result' },
                });
              }}
            >
              ดาวน์โหลดหนังสือรับรองฯ
            </div>
          </div>
        </div>
      </div>
    );
  }
}
