import React, { Component } from "react";
import { HttpClient } from "../../../services/HttpClient";

class Member extends Component<any, any> {
  httpClient = new HttpClient();

  constructor(props: any) {
    super(props);
    this.state = {
      customerDetail: {
        uid: "",
        clientNumber: "",
        email: "",
        mobile: "",
        address_line1: "",
        address_line2: "",
        province_code: "",
        amphur_code: "",
        tambon_code: "",
        postcode: "",
        policyNumber: "",
        dateOfBirth: ""
      },
      provinces: [],
      amphurs: [],
      tambons: [],
      loaded: true,
      agree: {},
    };
  }

  async componentDidMount() {
    this.setState({loaded: false});
    // const agreeStr = window.sessionStorage.getItem("consent_agree");
    // if (agreeStr) {
    //   const agree = JSON.parse(agreeStr);
    //   this.setState({ agree });
    // } else {
    //   this.props.history.push(`/smileclub/consent`);
    // }
    const sessionCustomer = sessionStorage.getItem("customerDetail");
    const sessionUID = sessionStorage.getItem("uid");
    if (sessionCustomer && sessionUID) {
      const customerDetail = JSON.parse(sessionCustomer);
      customerDetail.uid = sessionUID;
      await this.setState({ customerDetail });
    }
    this.getProvince();
    this.getAmphur(this.state.customerDetail.province_code);
    this.getTambon(
      this.state.customerDetail.province_code,
      this.state.customerDetail.amphur_code
    );
    this.setState({loaded: true});
  }

  async getProvince() {
    let provinces = await this.httpClient.get("/address/province");
    provinces = provinces.map((pro: any) => {
      if (pro.name === "กทม.") {
        pro.name = "กรุงเทพมหานคร";
      }
      return pro;
    });
    this.setState({ provinces });
  }

  async getAmphur(provinceCode: string) {
    const amphurs = await this.httpClient.get("/address/amphur", {
      provinceCode
    });
    this.setState({ amphurs });
  }

  async getTambon(provinceCode: string, amphurCode: string) {
    const tambons = await this.httpClient.get("/address/tambon", {
      provinceCode,
      amphurCode
    });
    this.setState({ tambons });
  }

  async getPostcode(
    provinceCode: string,
    amphurCode: string,
    tambonCode: string
  ) {
    const { customerDetail } = this.state;
    const postcode = await this.httpClient.get("/address/postcode", {
      provinceCode,
      amphurCode,
      tambonCode
    });
    customerDetail.postcode = postcode.postCode;
    this.setState({ customerDetail });
  }

  inputChange = async (e: any) => {
    const { name, value } = e.target;
    const customerDetail = this.state.customerDetail;
    customerDetail[name] = value;
    await this.setState({ customerDetail });
  };

  changeProvince = async (e: any) => {
    const { value } = e.target;
    const customerDetail = this.state.customerDetail;
    customerDetail["province_code"] = value;
    customerDetail["amphur_code"] = "01";
    customerDetail["tambon_code"] = "01";
    await this.setState({ customerDetail });
    this.getAmphur(value);
    this.getTambon(value, "01");
    this.getPostcode(value, "01", "01");
  };

  changeAmphur = async (e: any) => {
    const { value } = e.target;
    const customerDetail = this.state.customerDetail;
    customerDetail["amphur_code"] = value;
    customerDetail["tambon_code"] = "01";
    await this.setState({ customerDetail });
    this.getTambon(customerDetail.province_code, value);
    this.getPostcode(this.state.customerDetail.province_code, value, "01");
  };

  changeTambon = async (e: any) => {
    const { value } = e.target;
    const customerDetail = this.state.customerDetail;
    customerDetail["tambon_code"] = value;
    await this.setState({ customerDetail });
    this.getPostcode(
      this.state.customerDetail.province_code,
      this.state.customerDetail.amphur_code,
      value
    );
  };

  submitHandler = async () => {
    this.setState({loaded: false});
    const uid = window.sessionStorage.getItem('uid')
    const lastGroup = window.sessionStorage.getItem('last_group')
    const { customerDetail, agree } = this.state;
    const result = await this.httpClient.post(
      "/register/smileclub",
      { customer: customerDetail, agree, uid, lastGroup }
    );
    this.setState({loaded: true});
    if (result.fld_result === "completed") {
      this.props.history.push("/smileclub/result");
    } else {
      this.props.history.push("/smileclub/result?error");
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          id="loader"
          style={{ display: this.state.loaded ? "none" : "block" }}
        ></div>
        <div
          className="wrap-all member-info-wrap"
          style={{ display: this.state.loaded ? "block" : "none" }}
        >
          <div className="wrap-logo">
            <div className="logo-mtl">
              <img src="/images/MTL-logo.png" alt="logo" />
            </div>
            <div className="logo-smile">
              <img src="/images/smileClub-logo.png" alt="smileclubLogo" />
            </div>
          </div>
          <div className="member-content" style={{paddingBottom: '60px'}}>
            <div className="wrap-content">
              <h2>ข้อมูลสมาชิก</h2>
              <p className="check-info">กรุณาตรวจสอบรายละเอียดให้ถูกต้อง</p>
              <div className="register-smile smile-member-info">
                <p>อีเมล (Email.)</p>
                <div className="section_input member-input">
                  <input
                    type="text"
                    placeholder="email@example.com"
                    onChange={() => {}}
                    defaultValue={this.state.customerDetail.email}
                    disabled
                    style={{background: "#F9F9F9"}}
                  />
                </div>
                <p>เบอร์โทรศัพท์ (Mobile No.)</p>
                <div className="section_input member-input">
                  <input
                    type="text"
                    placeholder="081234xxxx"
                    onChange={() => {}}
                    defaultValue={this.state.customerDetail.mobile}
                    disabled
                    style={{background: "#F9F9F9"}}
                  />
                </div>
              </div>
            </div>
            <div className="line"></div>

            <div className="wrap-content address-content">
              <h2>ข้อมูลที่อยู่ของลูกค้า</h2>
              <p className="check-info">กรุณาตรวจสอบรายละเอียดให้ถูกต้อง</p>
              <div className="register-smile smile-member-info">
                <p>ที่อยู่</p>
                <div className="input-wrap">
                  <p className="address-text">เลขที่/หมู่</p>
                  <div className="address-input">
                    <input
                      type="text"
                      placeholder="99/99"
                      onChange={this.inputChange}
                      name="address_line1"
                      value={this.state.customerDetail.address_line1}
                    />
                  </div>
                </div>
                <div className="input-wrap">
                  <p className="address-text">ซอย/ถนน</p>
                  <div className="address-input">
                    <input
                      type="text"
                      placeholder="ลาดพร้าว"
                      onChange={this.inputChange}
                      name="address_line2"
                      value={this.state.customerDetail.address_line2}
                    />
                  </div>
                </div>
                <div className="input-wrap">
                  <p className="address-text">จังหวัด</p>
                  <div className="address-input select-province">
                    <select
                      name="province_code"
                      value={this.state.customerDetail.province_code}
                      onChange={this.changeProvince}
                    >
                      {this.state.provinces.map((province: any) => (
                        <option key={province.code} value={province.code}>
                          {province.name}
                        </option>
                      ))}
                    </select>
                    <div className="select_arrow-product"></div>
                  </div>
                </div>
                <div className="input-wrap">
                  <p className="address-text">เขต/อำเภอ</p>
                  <div className="address-input select-province">
                    <select
                      name="amphur_code"
                      value={this.state.customerDetail.amphur_code}
                      onChange={this.changeAmphur}
                    >
                      {this.state.amphurs.map((amphur: any) => (
                        <option key={amphur.code} value={amphur.code}>
                          {amphur.name}
                        </option>
                      ))}
                    </select>
                    <div className="select_arrow-product"></div>
                  </div>
                </div>
                <div className="input-wrap">
                  <p className="address-text">เเขวง/ตำบล</p>
                  <div className="address-input select-province">
                    <select
                      name="tambon_code"
                      value={this.state.customerDetail.tambon_code}
                      onChange={this.changeTambon}
                    >
                      {this.state.tambons.map((tambon: any) => (
                        <option key={tambon.code} value={tambon.code}>
                          {tambon.name}
                        </option>
                      ))}
                    </select>
                    <div className="select_arrow-product"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-signin-fixed" onClick={this.submitHandler}>
            ยืนยัน
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Member;
