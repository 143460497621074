import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Check from './pages/Check';
import Request from './pages/Request';
import Rule from './pages/Rule';
import Result from './pages/Result';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

export default class MTLTaxConsent extends Component<any, any> {
  routes = [
    {
      path: '/taxConsent/rule',
      component: Rule,
    },
    {
      path: '/taxConsent/check',
      component: Check,
    },
    {
      path: '/taxConsent/request',
      component: Request,
    },
    {
      path: '/taxConsent/result',
      component: Result,
    },
  ];

  async componentDidMount() {
    await liff
      .init({ liffId: process.env.REACT_APP_LIFF_TAX_CONCENT })
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login({
            redirectUri: window.location.href,
          });
          return;
        } else {
          const profile = await liff.getProfile();
          const lineAccessToken = await liff.getAccessToken();
          const response = await axios.post(
            `${API_URL}/register/getAccessToken`,
            { token: lineAccessToken },
          );
          const refresh_token = response.data.data;
          const responseClient = await axios.get(
            `${API_URL}/register/clientNumber/${profile.userId}`,
            { headers: { Authorization: `bearer ${refresh_token}` } },
          );
          const customerDetail = responseClient.data.data;
          const clientNumber = customerDetail.clientNumber;
          sessionStorage.setItem('refresh_token', refresh_token);
          sessionStorage.setItem('clientNumber', clientNumber);
          sessionStorage.setItem('uid', profile.userId);
          await this.setState({ hasToken: true });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  constructor(props: any) {
    super(props);
    this.state = {
      hasToken: false,
    };
  }

  RouteWithSubRoutes = (theProps: any) => {
    return (
      <Route
        path={theProps.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <theProps.component
            {...props}
            exact
            routes={theProps.routes}
            loadedToken={theProps.loadedToken}
          />
        )}
      />
    );
  };

  render() {
    return (
      <BrowserRouter basename={'/'}>
        <link rel="stylesheet" type="text/css" href="/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/css/responsive.css" />
        <Switch>
          {this.routes.map((route, i) => (
            <this.RouteWithSubRoutes
              key={i}
              {...route}
              loadedToken={this.state.hasToken}
            />
          ))}
          <Redirect exact from="/taxConsent" to="/taxConsent/rule" />
        </Switch>
      </BrowserRouter>
    );
  }
}
