import React, { Component } from "react";

export default class DownloadApp extends Component {
  
  getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor ;
    if (/android/i.test(userAgent)) {
      return "Android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  };

  downloadIOSApp = () => {
    const schema = "phoenix://";
    window.location.replace(schema);
    setTimeout(function() {
      window.location.href =
        "itms-apps://itunes.apple.com/th/app/mtl-click/id1440560140";
    }, 2000);
  };

  downloadAndroidApp = () => {
    const schema = "phoenix://healthcare";
    window.location.replace(schema);
    setTimeout(() => {
      window.location.href = "market://details?id=com.thmtlphoenix";
    }, 2000);
  };
  componentDidMount() {
    const os = this.getMobileOperatingSystem();
    if (os === "iOS") this.downloadIOSApp();
    else if (os === "Android") this.downloadAndroidApp();
    else {
      window.location.href = "https://www.muangthai.co.th/th/mtlclick";
    }
    setTimeout(() => {
      window.location.href = "https://www.muangthai.co.th/th/mtlclick";
    }, 1000);
  }

  render() {
   return (<p>Download Application</p>);
  }
}
