import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import Register from './pages/Register';
import OTPForm from './pages/OtpForm';
import Member from './pages/Member';
import Result from './pages/Result';
import Consent from './pages/Consent';
import './MTLSmileClub.css';
import { HttpClient } from '../../services/HttpClient';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

class Smileclub extends Component<any, any> {
  routes = [
    {
      path: '/smileclub/consent',
      component: Consent,
    },
    {
      path: '/smileclub/result',
      component: Result,
    },
    {
      path: '/smileclub/register-form',
      component: Register,
    },
    {
      path: '/smileclub/OTP',
      component: OTPForm,
    },
    {
      path: '/smileclub/member',
      component: Member,
    },
  ];

  async componentDidMount() {
    document.title = 'เมืองไทยสไมล์คลับ | บมจ.เมืองไทยประกันชีวิต';
    // liff.init({ liffId: "1475387349-g21BNLKP" }) // production
    liff
      .init({ liffId: process.env.REACT_APP_LIFF_SMILE_CLUB }) // staging
      // liff.init({ liffId: "1654775127-LqknZQ46" }) // test
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const profile = await liff.getProfile();
          const lineAccessToken = liff.getAccessToken();
          const response = await axios.post(
            `${API_URL}/register/getAccessToken`,
            { token: lineAccessToken },
          );
          const refresh_token = response.data.data;
          const responseClient = await axios.get(
            `${API_URL}/register/clientNumber/${profile.userId}`,
            { headers: { Authorization: `bearer ${refresh_token}` } },
          );
          const customerDetail = responseClient.data.data;
          const clientNumber = customerDetail.clientNumber;
          sessionStorage.setItem('refresh_token', refresh_token);
          sessionStorage.setItem('clientNumber', clientNumber);
          sessionStorage.setItem(
            'customerDetail',
            JSON.stringify(customerDetail),
          );
          sessionStorage.setItem('uid', profile.userId);
          const httpClient = new HttpClient();
          await httpClient.post(`/customers/tracking`, {
            uid: sessionStorage.getItem('uid'),
            trackingKey: 'smileclub-register',
          });
          await this.setState({ hasToken: true });
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  constructor(props: any) {
    super(props);
    this.state = {
      hasToken: false,
    };
  }

  RouteWithSubRoutes = (theProps: any) => {
    return (
      <Route
        path={theProps.path}
        render={(props) => (
          // pass the sub-routes down to keep nesting
          <theProps.component
            {...props}
            exact
            routes={theProps.routes}
            loadedToken={theProps.loadedToken}
          />
        )}
      />
    );
  };

  render() {
    return (
      <BrowserRouter basename={'/'}>
        <link rel="stylesheet" type="text/css" href="/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/css/responsive.css" />
        <Switch>
          {this.routes.map((route, i) => (
            <this.RouteWithSubRoutes
              key={i}
              {...route}
              loadedToken={this.state.hasToken}
            />
          ))}
          <Redirect exact from="/smileclub" to="/smileclub/register-form" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Smileclub;
