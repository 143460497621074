import axios from 'axios';
import React from 'react';

const API_URL = (process.env.REACT_APP_API_URL as string) || '/api';
const liff = window.liff;

export default class TrackingUrl extends React.Component<any, any> {
  async componentDidMount() {
    try {
      await liff.init({ liffId: process.env.REACT_APP_LIFF_TRACKING_URL }); // staging
      if (!liff.isLoggedIn()) {
        liff.login({
          redirectUri: window.location.href,
        });
        return;
      }
      const profile = await liff.getProfile();
      const lineAccessToken = await liff.getAccessToken();
      const response = await axios.post(`${API_URL}/register/getAccessToken`, {
        token: lineAccessToken,
      });
      const refresh_token = response.data.data;
      await this.setState({ hasToken: true });
      const params = new URLSearchParams(this.props.location.search);
      const key = params.get('key');
      const id = params.get('id');
      const tel = params.get('tel');
      console.log(tel);
      let url: any = '';
      if (key === 'tel' || key === 'map') {
        url = await this.getUrl(
          { type: key, uid: profile.userId },
          `/location/tracking/${id}`,
          refresh_token,
        );
        window.location.href = url;
      } else if (key === 'product') {
        url = await this.getUrl(
          { uid: profile.userId },
          `/product/tracking/${id}`,
          refresh_token,
        );
        window.location.href = url;
      } else if (tel === 'tel:1766') {
        console.log(tel);
        url = await this.getUrl(
          { uid: profile.userId, trackingKey: 'call-linecc' },
          `/customers/tracking`,
          refresh_token,
        );

        window.location.href = tel;
      } else {
        url = await this.getUrl(
          { key: key, uid: profile.userId, richmenuId: id },
          '/customers/tracking/url',
          refresh_token,
        );
        window.location.href = url.data;
      }
      this.setState({ loaded: true });
    } catch (error) {
      console.log(error);
    }
  }

  constructor(props: any) {
    super(props);
    this.state = {
      hasToken: false,
    };
  }
  getUrl = async (key: any, link: any, token: any) => {
    const response = await axios.post(`${API_URL}${link}`, key, {
      headers: { Authorization: `bearer ${token}` },
    });

    return response.data;
  };
  render() {
    return <div>Waiting For Redirect</div>;
  }
}
